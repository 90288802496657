import Switch from '@mui/material/Switch'
import useAuth from 'hooks/useAuth'
import { useLocation, useNavigate } from 'react-router-dom'
import { Role, RoleAction } from 'types/playInApiInterfaces'

export enum RouteName {
  ArticleManagement = 'ArticleManagement',
  CardDetails = 'CardDetails',
  CardManagement = 'CardManagement',
  CashboxDetails = 'CashboxDetails',
  CashboxCreate = 'CashboxCreate',
  CashboxManagement = 'CashboxManagement',
  CollaboratorAccountOptions = 'CollaboratorAccountOptions',
  CollaboratorDetails = 'CollaboratorDetails',
  CollaboratorManagement = 'CollaboratorManagement',
  CollectionCreate = 'CollectionCreate',
  CollectionDetails = 'CollectionDetails',
  CollectionManagement = 'CollectionManagement',
  CustomerDetails = 'CustomerDetails',
  CustomerManagement = 'CustomerManagement',
  GiftCardManagement = 'GiftCardManagement',
  OrderPrint = 'OrderPrint',
  OrderDetails = 'OrderDetails',
  OrderManage = 'OrderManage',
  OrderPreparationManage = 'OrderPreparationManage',
  PermissionManage = 'PermissionManage',
  PricingTag = 'PricingTag',
  ProductDetails = 'ProductDetails',
  ProductManagement = 'ProductManagement',
  ProductSheetRequest = 'ProductSheetRequest',
  ProductRequestManagement = 'ProductRequestManagement',
  RestockStorePrint = 'RestockStorePrint',
  StoreLabels = 'StoreLabels',
  StockDetails = 'StockDetails',
  StockRange = 'StockRange',
  StockQuantityAlert = 'StockQuantityAlert',
  StockManagement = 'StockManagement',
  StoreEventManage = 'StoreEventManage',
  StoreEventDetails = 'StoreEventDetails',
  StoreEventCreate = 'StoreEventCreate',
  ShippingLabelLog = 'ShippingLabelLog',
  StoreManagement = 'StoreManagement',
  StoreDetails = 'StoreDetails',
  BannerCreate = 'BannerCreate',
  BannerEdit = 'BannerEdit',
  BannerManagement = 'BannerManagement'
}

type RouteConfig = {
  name?: RouteName
  path: string
  permissions?: RoleAction[]
}

export const routesTreeConfig: Array<RouteConfig> = [
  {
    name: RouteName.ArticleManagement,
    path: '/article-management',
    permissions: [RoleAction.ROLE_ACTION_LINKED_ARTICLE_EDIT]
  },
  {
    name: RouteName.CardDetails,
    path: '/card-details/:id',
    permissions: [RoleAction.ROLE_ACTION_CARDS_READ]
  },
  {
    name: RouteName.CardManagement,
    path: '/card-manage'
    //TODO Hide page without role for now
    /*permissions: [RoleAction.ROLE_ACTION_CARDS_READ]*/
  },
  {
    name: RouteName.CashboxDetails,
    path: '/cashbox-details/:id',
    permissions: [RoleAction.ROLE_ACTION_CASHBOXES_WRITE]
  },
  {
    name: RouteName.CashboxCreate,
    path: '/cashbox-details',
    permissions: [RoleAction.ROLE_ACTION_CASHBOXES_WRITE]
  },
  {
    name: RouteName.CashboxManagement,
    path: '/cashbox-manage',
    permissions: [RoleAction.ROLE_ACTION_CASHBOXES_WRITE]
  },
  {
    name: RouteName.CollaboratorAccountOptions,
    path: '/collaborator-account-options',
    permissions: []
  },
  {
    name: RouteName.CollaboratorDetails,
    path: '/collaborator-details/:id',
    permissions: [RoleAction.ROLE_ACTION_COLLABORATORS_READ]
  },
  {
    name: RouteName.CollaboratorManagement,
    path: '/collaborator-manage',
    permissions: [RoleAction.ROLE_ACTION_COLLABORATORS_READ]
  },
  {
    name: RouteName.CollectionCreate,
    path: '/product-collection-create',
    permissions: [RoleAction.ROLE_ACTION_PRODUCT_COLLECTION_CREATE]
  },
  {
    name: RouteName.CollectionDetails,
    path: '/product-collection-details/:id',
    permissions: [RoleAction.ROLE_ACTION_PRODUCT_COLLECTION_CREATE]
  },
  {
    name: RouteName.CollectionManagement,
    path: '/product-collection-manage',
    permissions: [RoleAction.ROLE_ACTION_PRODUCT_COLLECTION_CREATE]
  },
  {
    name: RouteName.CustomerDetails,
    path: '/customer-details/:id',
    permissions: [RoleAction.ROLE_ACTION_CUSTOMERS_READ]
  },
  {
    name: RouteName.CustomerManagement,
    path: '/customer-manage',
    permissions: [RoleAction.ROLE_ACTION_CUSTOMERS_READ]
  },
  {
    name: RouteName.GiftCardManagement,
    path: '/giftcard-manage',
    permissions: [RoleAction.ROLE_ACTION_GIFTCARD_ACTIVATE]
  },
  {
    name: RouteName.OrderPrint,
    path: '/order-print',
    permissions: [
      RoleAction.ROLE_ACTION_ORDERS_PRINT,
      RoleAction.ROLE_ACTION_EXCLUDE_EXT_PRINT,
      RoleAction.ROLE_ACTION_ORDERS_PRINT_CARDS
    ]
  },
  {
    name: RouteName.OrderDetails,
    path: '/order-details/:id',
    permissions: [RoleAction.ROLE_ACTION_ORDERS_READ]
  },
  {
    name: RouteName.OrderManage,
    path: '/order-manage',
    permissions: [RoleAction.ROLE_ACTION_ORDERS_READ]
  },
  {
    name: RouteName.OrderPreparationManage,
    path: '/order-preparation-manage',
    permissions: [RoleAction.ROLE_ACTION_ORDERS_EDIT_STATUS_PREPARATION_CARDS]
  },
  {
    name: RouteName.PermissionManage,
    path: '/access-rights-manage',
    permissions: [RoleAction.ROLE_ACTION_PERMISSIONS_MANAGE_READ]
  },
  {
    name: RouteName.PricingTag,
    path: '/pricing-tag',
    permissions: [RoleAction.ROLE_ACTION_CARD_EDIT_PRICE]
  },
  {
    name: RouteName.ProductDetails,
    path: '/product-details/:id',
    permissions: [RoleAction.ROLE_ACTION_PRODUCTS_READ]
  },
  {
    name: RouteName.ProductManagement,
    path: '/product-manage',
    permissions: [RoleAction.ROLE_ACTION_PRODUCTS_READ]
  },
  {
    name: RouteName.ProductSheetRequest,
    path: '/product-sheet-request',
    permissions: [RoleAction.ROLE_ACTION_PRODUCT_CREATE_REQUEST, RoleAction.ROLE_ACTION_PRODUCT_CREATE]
  },
  {
    name: RouteName.ProductRequestManagement,
    path: '/product-request-manage',
    permissions: [RoleAction.ROLE_ACTION_PRODUCT_CREATE, RoleAction.ROLE_ACTION_PRODUCT_CREATE_REQUEST]
  },
  {
    name: RouteName.RestockStorePrint,
    path: '/restock-store-print',
    permissions: [RoleAction.ROLE_ACTION_RESTOCKS_PRINT]
  },
  {
    name: RouteName.StoreLabels,
    path: '/store-labels-manage',
    permissions: [RoleAction.ROLE_ACTION_STORE_LABEL_PRINT]
  },
  {
    name: RouteName.StockDetails,
    path: '/stock-details/:id',
    permissions: [RoleAction.ROLE_ACTION_STOCKS_READ]
  },
  {
    name: RouteName.StockRange,
    path: '/stock-range',
    permissions: [RoleAction.ROLE_ACTION_STOCKS_STATS_READ]
  },
  {
    name: RouteName.StockQuantityAlert,
    path: '/stock-quantity-alert'
  },
  {
    name: RouteName.StockManagement,
    path: '/stock-manage',
    permissions: [RoleAction.ROLE_ACTION_STOCKS_READ]
  },
  {
    name: RouteName.StoreEventManage,
    path: '/store-event-manage',
    permissions: [RoleAction.ROLE_ACTION_STORE_EVENTS_READ]
  },
  {
    name: RouteName.StoreEventDetails,
    path: '/store-event-details/:id',
    permissions: [RoleAction.ROLE_ACTION_STORE_EVENTS_READ]
  },
  {
    name: RouteName.StoreEventCreate,
    path: '/store-event-create',
    permissions: [RoleAction.ROLE_ACTION_STORE_EVENT_CREATE]
  },
  {
    name: RouteName.ShippingLabelLog,
    path: '/shipping-label-manage',
    permissions: [RoleAction.ROLE_ACTION_ORDER_EDIT_SEND]
  },
  {
    name: RouteName.StoreManagement,
    path: '/store-manage',
    permissions: [RoleAction.ROLE_ACTION_STORES_READ]
  },
  {
    name: RouteName.StoreDetails,
    path: '/store-details/:id',
    permissions: [RoleAction.ROLE_ACTION_STORES_READ]
  },
  {
    name: RouteName.BannerCreate,
    path: '/banner-create'
  },
  {
    name: RouteName.BannerEdit,
    path: '/banner-details/:id'
  },
  {
    name: RouteName.BannerManagement,
    path: '/banner-management'
  }
]

export const useCheckMatch = () => {
  const { me, accessControl } = useAuth()
  const checkMatch = (componentPermissions?: RoleAction[]) => {
    if (!accessControl || componentPermissions?.length === 0) {
      return true
    }

    // It might not be so simple is we want multiple permissions to match
    return !!componentPermissions?.some((p) => p && me?.reachableActions?.includes(p))
  }

  const hasRole = (componentPermissions?: Role[]) => {
    if (!accessControl || componentPermissions?.length === 0) {
      return true
    }

    // It might not be so simple is we want multiple permissions to match
    return !!componentPermissions?.some((p) => p && me?.reachableRoles?.includes(p))
  }

  return {
    checkMatch,
    hasRole
  }
}

export const PermissionActivationToggle = () => {
  const { setAccessControl, accessControl } = useAuth()

  const location = useLocation()
  const navigate = useNavigate()

  const onPermissionDisabled = () => {
    // @ts-ignore
    if (accessControl && location.state?.from?.pathname) {
      // @ts-ignore
      navigate(location.state.from.pathname)
    }
    setAccessControl(!accessControl)
  }

  return (
    <>
      {!accessControl ? 'Activer' : 'Desactiver'} la gestion des droits
      <Switch checked={accessControl} onChange={onPermissionDisabled} />
    </>
  )
}

export const getPath = (routeName: RouteName): string => {
  return routesTreeConfig.find((route) => route.name === routeName)?.path ?? '/404'
}
