import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { CardEditionStoryblokStory } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type PostCardEditionStoryblokStoriesResponse = Api.PostCardEditionStoryblokStories.ResponseBody
export const postCardEditionStoryblokStory = async (body: PostCardEditionStoryblokStoriesResponse) => {
  const res = await playInApi.post<
    PostCardEditionStoryblokStoriesResponse,
    AxiosResponse<PostCardEditionStoryblokStoriesResponse>
  >(API_ROUTES.cardEditionStoryblokStories.root, body)

  return res.data
}

type FetchCardEditionStoryblokStoriesResponse = Api.GetCardEditionStoryblokStories.ResponseBody
export const fetchCardEditionStoryblokStories = async (): Promise<CardEditionStoryblokStory[]> => {
  const res = await playInApi.get<FetchCardEditionStoryblokStoriesResponse>(API_ROUTES.cardEditionStoryblokStories.root)

  return res.data['hydra:member']
}

export type DeleteCardEditionStoryblokStories = Api.DeleteCardEditionStoryblokStoriesId.RequestBody
export const deleteCardEditionStoryblokStories = async (id: number | undefined) => {
  const res = await playInApi.delete<
    DeleteCardEditionStoryblokStories,
    AxiosResponse<DeleteCardEditionStoryblokStories>
  >(`${API_ROUTES.cardEditionStoryblokStories.root}/${id}`)

  return res.data
}
