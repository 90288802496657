import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey, useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

/* ---------------------------------- FETCH --------------------------------- */

export type FetchCollectionParams = Api.GetProductCollections.RequestQuery
export const getCollectionsUri = (params: FetchCollectionParams) => {
  const qs = stringify(params)
  return [API_ROUTES.collections.root, qs].join('?')
}

export type FetchPaginatedCollectionResponse = Api.GetProductCollections.ResponseBody['hydra:member'][0]

export const usePaginatedProductCollections = (
  queryKey: QueryKey,
  params: FetchCollectionParams,
  options?: PaginatedHookOptions<FetchPaginatedCollectionResponse>
) => usePaginatedQuery<FetchPaginatedCollectionResponse>(queryKey, getCollectionsUri(params), options)

export const productCollectionByIdQueryKey = (id: string | number) => ['productCollection', `${id}`]

type FetchProductCollectionByIdResponse = Api.GetProductCollectionsId.ResponseBody
const fetchProductCollectionById = async (id: string | number) => {
  const res = await playInApi.get<FetchProductCollectionByIdResponse>(`${API_ROUTES.collections.root}/${id}`)
  return res.data
}

export const useProductCollectionById = (id: string | number) => {
  const navigate = useNavigate()

  return useQuery<FetchProductCollectionByIdResponse, AxiosResponse>(
    productCollectionByIdQueryKey(id),
    () => fetchProductCollectionById(id),
    {
      onError: (error) => {
        // replace: true, we prevent going back to the page that respond 40X
        if (error) {
          //to avoid 404 on NS_BINDING_ABORTED error on Firefox
          navigate(`/${error?.status === 403 ? 403 : 404}`, { replace: true })
        }
      }
    }
  )
}

/* ---------------------------------- POST ---------------------------------- */

export type PostCollectionBody = Api.PostProductCollections.RequestBody
type PostCollectionResponse = Api.PostProductCollections.ResponseBody

export const postCollection = async (body: PostCollectionBody) => {
  const res = await playInApi.post<PostCollectionBody, AxiosResponse<PostCollectionResponse>>(
    API_ROUTES.collections.root,
    body
  )
  return res.data
}

export type PostCollectionImageBody = Api.PostProductCollectionsIdImage.RequestBody
export const postCollectionImage = async (id: string | number, body: FormData) => {
  const res = await playInApi.post(`${API_ROUTES.collections.root}/${id}/image`, body, {
    headers: {
      'Content-type': 'multipart/form-data'
    }
  })
  return res.data
}

/* ---------------------------------- PATCH --------------------------------- */

export type PatchProductCollectionBody = Api.PatchProductCollectionsId.RequestBody
type PatchProductCollectionResponse = Api.PatchProductCollectionsId.ResponseBody

export const patchProductCollection = async (id: string | number, body: PatchProductCollectionBody) => {
  const res = await playInApi.patch<PatchProductCollectionBody, AxiosResponse<PatchProductCollectionResponse>>(
    `${API_ROUTES.collections.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

/* --------------------------------- DELETE --------------------------------- */
export const deleteProductCollection = async (id: string | number) =>
  await playInApi.delete(`${API_ROUTES.collections.root}/${id}`)
