import { useGetLitigationsByCustomer } from 'api/litigations'
import { GetOrderByIdResponse } from 'api/orders'
import { getRecipientNameFromOrder, useOrderContext } from 'app/OrderDetails/helper'
import OrderCustomerEditModal from 'app/OrderDetails/modals/CustomerEditModal/CustomerEditModal'
import Button from 'components/Button/Button'
import { ColumnContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import Tabs from 'components/Tabs/Tabs'
import { Text } from 'components/Text/Text.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { EditIcon } from 'constants/icons'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import { RouteName, useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { Litigation } from 'types/entities'
import { OrderStatus, RoleAction } from 'types/playInApiInterfaces'
import AddressPanel from './AddressPanel'
import { AddressSectionContainer, HorizontalSeparator, StyledLink } from './OrderInfoCard.style'

type AddressTabsProps = {
  order?: GetOrderByIdResponse
}

function getAddressPanelFrom(
  t: TFunction<'translation'>,
  customerLitigations: Litigation[],
  order?: GetOrderByIdResponse
) {
  if (!order) {
    return <></>
  }

  const recipientName = getRecipientNameFromOrder(order)
  // Relay
  if (!!order.address?.relatedRelay) {
    const relayIntermediate = t('page.order.detail.relayIntermediate', {
      relayId: order.address?.relatedRelay?.postalReference,
      relayName: order.address?.relatedRelay?.name
    })
    return (
      <AddressPanel
        title={relayIntermediate}
        relayClickCollectRecipient={recipientName}
        address={order?.address?.relatedRelay?.streetAddress}
        postalCode={order?.address?.relatedRelay?.postalCode}
        city={order?.address?.relatedRelay?.city}
        customerLitigations={customerLitigations}
      />
    )
  }
  // Related store
  if (!!order.address?.relatedStoreAddress) {
    return (
      <AddressPanel
        title={order.address?.relatedStoreAddress?.name}
        relayClickCollectRecipient={recipientName}
        address={order?.address?.relatedStoreAddress?.address}
        postalCode={order?.address?.relatedStoreAddress?.zipCode}
        city={order?.address?.relatedStoreAddress?.city}
        state={order?.address?.relatedStoreAddress?.state}
        country={order?.address?.relatedStoreAddress?.country?.name}
        customerLitigations={customerLitigations}
      />
    )
  }
  // Regular customer
  return (
    <AddressPanel
      title={recipientName}
      address={order?.address?.recipientStreet}
      extraInformations={order?.address?.recipientExtraInformations}
      postalCode={order?.address?.recipientZipCode}
      city={order?.address?.recipientCity}
      state={order?.address?.recipientState}
      country={order?.address?.recipientCountry?.name}
      customerLitigations={customerLitigations}
    />
  )
}

const addressFrozenStatuses = [OrderStatus.Canceled, OrderStatus.CanceledRequested]

const addressProtectedStatuses = [
  OrderStatus.Canceled,
  OrderStatus.CanceledRequested,
  OrderStatus.Send,
  OrderStatus.Treated
]

function AddressTabs({ order }: AddressTabsProps) {
  const { t } = useTranslation()
  const { checkMatch } = useCheckMatch()
  const { data: customerLitigations } = useGetLitigationsByCustomer(order?.customer?.id!, {
    enabled: !!order
  })

  const [customerEditModalOpen, toggleCustomerEditModal] = useState(false)

  // Billing address for Relay-based and click-and-collect orders should be recipient's,
  // otherwise there may be a single shipping address (and, optionally, a different billing address).
  const hasBillingAddress =
    !!order?.address?.relatedRelay || !!order?.address?.relatedStoreAddress || !!order?.address?.billingName

  const tabs = [
    {
      tabLabel: hasBillingAddress
        ? t('common.label.shippingAddress')
        : t('page.order.detail.shippingAndBillingAddress'),
      noBackground: true,
      panelComponent: getAddressPanelFrom(t, customerLitigations ?? [], order)
    }
  ]

  if (hasBillingAddress) {
    tabs.push({
      tabLabel: t('common.label.billingAddress'),
      noBackground: true,
      panelComponent: (
        <>
          {order.address?.billingName ? (
            <AddressPanel
              title={order.address?.billingName}
              address={order?.address?.billingStreet}
              postalCode={order?.address?.billingZipCode}
              city={order?.address?.billingCity}
              customerLitigations={customerLitigations}
            />
          ) : (
            <AddressPanel
              title={order.address?.recipientName}
              address={order?.address?.recipientStreet}
              extraInformations={order?.address?.recipientExtraInformations}
              postalCode={order?.address?.recipientZipCode}
              city={order?.address?.recipientCity}
              country={order?.address?.recipientCountry?.name}
              customerLitigations={customerLitigations}
            />
          )}
        </>
      )
    })
  }

  const { inMyStoresOrder } = useOrderContext()

  return (
    <>
      <Tabs tabs={tabs} />
      <AddressSectionContainer>
        <ColumnContainer>
          {order?.customer?.email && (
            <TooltipHover id="customer-email" content={t('page.order.detail.redirectCustomerTooltip')}>
              <StyledLink route={RouteName.CustomerDetails} value={order?.customer.id}>
                {order?.email}
              </StyledLink>
            </TooltipHover>
          )}
          {order?.purchaseOrder && <Text>{t('page.order.detail.purchaseOrder', { id: order?.purchaseOrder })}</Text>}
        </ColumnContainer>
        <HorizontalSeparator />
        <ColumnContainer>
          {order?.address?.recipientPhone && <Text>{order?.address?.recipientPhone}</Text>}
          {!order?.address?.recipientPhone && order?.address?.recipientHomePhone && (
            <Text>{order?.address?.recipientHomePhone}</Text>
          )}
          {order?.hiboutikId && <Text>{t('page.order.detail.hiboutikNumber', { id: order.hiboutikId })}</Text>}
        </ColumnContainer>

        <ShouldDisable
          permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_CUSTOMER_NOT_TREATED]}
          deniedExtraCondition={!inMyStoresOrder}
        >
          <Button
            shape="circle"
            icon={EditIcon}
            variant="white"
            disabled={(checkMatch([RoleAction.ROLE_ACTION_ORDER_EDIT_CUSTOMER_TREATED])
              ? addressFrozenStatuses
              : addressProtectedStatuses
            ).includes(order?.status!)}
            onClick={() => toggleCustomerEditModal(true)}
          />
        </ShouldDisable>

        <OrderCustomerEditModal
          open={customerEditModalOpen}
          onClose={() => {
            toggleCustomerEditModal(false)
          }}
          order={order}
        />
      </AddressSectionContainer>
    </>
  )
}

export default AddressTabs
