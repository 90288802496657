import { useGetCountries } from 'api/countries'
import { fetchEmailTypes, FetchEmailTypesQueryParams } from 'api/emailTypes'
import { useGetLitigationCategories } from 'api/litigations'
import { FetchOrdersQueryParams, GetOrderByIdResponse } from 'api/orders'
import { getPaymentModes, GetPaymentModesParams } from 'api/paymentMode'
import {
  FetchProductCategoriesParams,
  PaginatedProductCategoriesOptions,
  usePaginatedProductCategories,
  useProductCategoryById
} from 'api/productCategories'
import { fetchRanges, FetchRangesQueryParams } from 'api/ranges'
import { fetchShippingModes } from 'api/shippingModes'
import { useStorePlaceOrders } from 'api/storePlaceOrders'
import { getWarehouseQueryKey } from 'api/stores'
import { fetchVoucherCategories } from 'api/vouchers'
import { Filter } from 'components/FiltersList/FiltersList'
import { Option } from 'components/Select/Select'
import { WAREHOUSE_STORE_ID } from 'constants/configs'
import { getName } from 'i18n-iso-countries'
import _, { sortBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { Collaborator, EmailType, PaymentMode, ShippingMode, Store } from 'types/entities'
import { CustomStoreEventStatus, OrderProgress, StoreEventStatusOptions } from 'types/enums'
import {
  CustomerInternalType,
  CustomerType,
  FidelityPointCategory,
  OrderStatus,
  ShippingTypeName,
  StockStatus,
  StoreEventStatus as EventStatus,
  VoucherCategoryName
} from 'types/playInApiInterfaces'
import { useGetPaymentModeLabel } from './entityHooks/paymentModeHooks'
import useAuth from './useAuth'

function getSelectOptions<T>(entities: T[] | undefined, getLabel: (entity: T) => string) {
  return [
    ...(entities || []).map((entity) => ({
      value: entity['@id'] as string,
      label: getLabel(entity)
    }))
  ]
}

export const getFilterLabel = (options: Option[], value?: string) => {
  return options.find((opt) => opt.value === value)?.label
}

export const useEmailTypeFilters = (params?: FetchEmailTypesQueryParams, enabled: boolean = true) => {
  const [emailTypes, setEmailTypes] = useState<EmailType[]>([])
  const [emailTypeOptions, setEmailTypeOptions] = useState<Option[]>([])

  useQuery(['email-types', params], () => fetchEmailTypes(params), {
    onSuccess: (emailTypesRes) => {
      setEmailTypes(emailTypesRes)
      setEmailTypeOptions(getSelectOptions(emailTypesRes, (entity) => entity.title!))
    },
    enabled
  })

  function getEmailTypeQueryParam(value: string) {
    return {
      emailType: value
    }
  }

  return {
    emailTypes,
    emailTypeOptions,
    getEmailTypeFilter: (id: string, value?: string) => ({
      id,
      label: emailTypeOptions.find((el) => el.value === value)
    }),
    getEmailTypeQueryParam
  }
}

type ShippingModeQueryOptions = {
  enabled: boolean
}

const defaultOptions = { enabled: true }
export type ShippingModeOption = Option & { price: number }

export const useShippingModeFilters = (uri: string, options: ShippingModeQueryOptions = defaultOptions) => {
  const [shippingModes, setShippingModes] = useState<ShippingMode[]>([])
  const [shippingModeOptions, setShippingModeOptions] = useState<ShippingModeOption[]>([])

  const { isFetched, isFetching } = useQuery(['shippingModes', uri], () => fetchShippingModes(uri), {
    enabled: options.enabled && !!uri,
    onSuccess: (shippingModesRes) => {
      setShippingModes(shippingModesRes)
      setShippingModeOptions(
        shippingModesRes.map((mode) => ({
          label: mode.name!,
          value: mode['@id'],
          price: mode.price!
        }))
      )
    }
  })

  function getShippingModeQueryParam(value: string) {
    return {
      shippingMode: value
    }
  }

  return {
    isFetched,
    isFetching,
    shippingModeOptions,
    getShippingModeFilter: (id: string, value?: string) => ({ id, label: getFilterLabel(shippingModeOptions, value) }),
    getShippingModeQueryParam,
    getShippingModeTypeFrom: useCallback(
      (value?: string | null) => shippingModes.find((el) => el['@id'] === value)?.shippingType,
      [shippingModes]
    )
  }
}

export const usePaymentModeFilters = (params?: GetPaymentModesParams) => {
  const { getPaymentModeLabel } = useGetPaymentModeLabel()

  const { isLoading, data: paymentModes } = useQuery(['paymentModes', params], () => getPaymentModes(params), {
    staleTime: 5 * 60 * 1000
  })

  const paymentModeOptions: Option[] = useMemo(
    () =>
      getSelectOptions(
        sortBy(paymentModes, (value) => getPaymentModeLabel(value.codeName)),
        (entity) => getPaymentModeLabel(entity.codeName) ?? entity.codeName
      ) ?? [],
    [paymentModes, getPaymentModeLabel]
  )

  function getPaymentModeQueryParam(value: string) {
    return {
      paymentMode: value
    }
  }

  return {
    paymentModes,
    paymentModeOptions,
    paymentModeOptionsPaginationProps: { isLoading },
    getPaymentModeFilter: (id: string, value?: string) => ({ id, label: getFilterLabel(paymentModeOptions, value) }),
    getPaymentModeQueryParam,
    getPaymentModeByValue: useCallback(
      (value?: any, key?: keyof PaymentMode) =>
        paymentModes?.find((paymentMode) => paymentMode[key ?? '@id'] === value),
      [paymentModes]
    )
  }
}

export const usePaymentStatusFilters = () => {
  const { t } = useTranslation()

  const paymentStatusOptions = [
    { label: t('common.select.paymentStatus.notReceived'), value: false },
    { label: t('common.select.paymentStatus.received'), value: true }
  ]
  interface PaymentStatusQueryParams {
    paymentReceived?: boolean
  }

  const getPaymentStatusQueryParams = (value?: boolean): PaymentStatusQueryParams => ({ paymentReceived: value })

  return {
    paymentStatusOptions,
    getPaymentStatusFilter: (id: string, value?: string) => ({
      id,
      label: getFilterLabel(paymentStatusOptions, value)
    }),
    getPaymentStatusQueryParams
  }
}

export type MyStoresOptions = {
  onlyActive?: boolean
}

const getMyStocks = (me: Collaborator, warehouse?: Store, options?: MyStoresOptions): Store[] => {
  const myStocks =
    me.stores?.filter((store) => {
      return store['@id'] !== WAREHOUSE_STORE_ID && (options?.onlyActive ? !!store.active : true)
    }) ?? []

  if (!warehouse) {
    return myStocks
  }

  return [warehouse, ...myStocks]
}

export const useMyStocksOptions = (options?: MyStoresOptions) => {
  const { t } = useTranslation()
  const { me } = useAuth()
  const queryClient = useQueryClient()
  const warehouse = queryClient.getQueryData<Store>(getWarehouseQueryKey)

  const myStocks = getMyStocks(me!, warehouse, options)

  const myStockOptions: Option[] = getSelectOptions(
    myStocks,
    (store) => `${t('common.label.stock')} ${store.shortName}`
  )

  return {
    myStocks,
    myStockOptions,
    getStockFilters: (id: string, value?: string) => ({ id, label: getFilterLabel(myStockOptions, value) }),
    getStockQueryParams: (value?: string) => ({
      stock: value
    })
  }
}

export const useStorePlaceOrderOptions = () => {
  const { data: storePlaceOrders } = useStorePlaceOrders()
  const storePlaceOrderOptions = storePlaceOrders?.map((el) => ({ label: el.name!, value: el.name! }))
  return {
    storePlaceOrderOptions
  }
}

const useStoresOptions = (stores?: Store[], options?: MyStoresOptions) => {
  const storeCollection = options?.onlyActive ? stores?.filter((store) => store.active) : stores
  const storesOptions = getSelectOptions(storeCollection, (store) => `${store.shortName}`)

  return {
    storesOptions,
    getStoreFilter: (id: string, value?: string) => ({ id, label: getFilterLabel(storesOptions, value) }),
    getStoreQueryParams: (value?: string) => ({ store: value })
  }
}

export const useMyStoresOptions = (options?: MyStoresOptions) => {
  const { me } = useAuth()
  const { getStoreFilter, getStoreQueryParams, storesOptions } = useStoresOptions(me?.stores || [], options)

  return {
    myStores: me?.stores,
    myStoresOptions: storesOptions,
    getStoreFilter,
    getStoreQueryParams
  }
}

export const useClientProfileOptions = () => {
  const { t } = useTranslation()

  const clientProfileOptions = useMemo(
    () =>
      _.sortBy(
        [
          {
            value: CustomerType.CARDMARKET,
            label: t('common.select.clientProfile.cardmarket')
          },
          {
            value: CustomerType.CARDTRADER,
            label: t('common.select.clientProfile.cardtrader')
          },
          {
            value: CustomerType.EMPLOYEE,
            label: t('common.select.clientProfile.employee'),
            disabled: true
          },
          {
            value: CustomerType.INTERNAL,
            label: t('common.select.clientProfile.playinAccount')
          },
          {
            value: CustomerType.CUSTOMER,
            label: t('common.select.clientProfile.clientAccount')
          },
          {
            value: CustomerType.SELLERMANIA,
            label: t('common.select.clientProfile.sellermania')
          }
        ],
        (e) => e.label.toLocaleLowerCase()
      ),
    [t]
  )

  const internalAccountOptions = useMemo(
    () =>
      _.sortBy(
        [
          {
            value: CustomerInternalType.CardReporting,
            label: t('common.select.internalAccount.cardReporting')
          },
          {
            value: CustomerInternalType.Event,
            label: t('common.select.internalAccount.event')
          },
          {
            value: CustomerInternalType.Inventory,
            label: t('common.select.internalAccount.inventory')
          },
          {
            value: CustomerInternalType.InventoryError,
            label: t('common.select.internalAccount.inventoryError')
          },
          {
            value: CustomerInternalType.MarketingOffer,
            label: t('common.select.internalAccount.marketingOffer')
          },
          {
            value: CustomerInternalType.OrderPreparation,
            label: t('common.select.internalAccount.orderPreparation')
          },
          {
            value: CustomerInternalType.Reporting,
            label: t('common.select.internalAccount.reporting')
          },
          {
            value: CustomerInternalType.Shop,
            label: t('common.select.internalAccount.shop')
          }
        ],
        (e) => e.label.toLocaleLowerCase()
      ),
    [t]
  )

  const allProfileOptions: Option[] = [
    ...clientProfileOptions,
    { label: '--------------------', value: 'separator', disabled: true },
    ...internalAccountOptions
  ]

  const getClientProfileQueryParams = (value: string | CustomerType) => {
    if (value in CustomerType) return { 'customer.accountType': value }
    else return { 'customer.playinAccountType.codeName': value }
  }

  const getMultipleClientProfileQueryParams = (values: Array<string | CustomerType>) => {
    let qp = {
      'customer.playinAccountType.codeName[]': [] as string[],
      'customer.accountType[]': [] as string[]
    }
    for (let value of values) {
      if (value in CustomerType) qp['customer.accountType[]'].push(value)
      else qp['customer.playinAccountType.codeName[]'].push(value)
    }
    return qp
  }

  return {
    clientProfileOptions: allProfileOptions,
    getClientProfileFilter: (id: string, value?: string) => ({
      id,
      label: getFilterLabel(allProfileOptions, value)
    }),
    getMultipleClientProfileFilter: (id: string, values: string[] = []) =>
      values.map((clientProfile) => ({
        id,
        label: getFilterLabel(allProfileOptions, clientProfile),
        value: clientProfile,
        filterId: `clientProfile-${clientProfile}`
      })),
    getClientProfileQueryParams,
    getMultipleClientProfileQueryParams
  }
}

export const useStockStateOptions = () => {
  const { t } = useTranslation()

  const stockStateOptions = [
    { label: t('common.select.stockStatus.available'), value: 'available' },
    { label: t('common.select.stockStatus.preorder'), value: 'preorder' },
    { label: t('common.select.stockStatus.restock'), value: 'restock' }
  ]

  const getStockStateQueryParams = (value?: string) => {
    switch (value) {
      case 'available':
        return { 'preorderAt[before]': 'now', 'restockedAt[before]': 'now' }
      case 'preorder':
        return { 'preorderAt[after]': 'now', 'exists[preorderAt]': true }
      case 'restock':
        return { 'restockedAt[after]': 'now', 'exists[restockedAt]': true }
      default:
        return {}
    }
  }

  return {
    stockStateOptions,
    getStockStateFilter: (id: string, value?: string) => ({ id, label: getFilterLabel(stockStateOptions, value) }),
    getStockStateQueryParams
  }
}

export const useCountryOptions = () => {
  const { i18n } = useTranslation()
  const [countryOptions, setCountryOptions] = useState<Option[]>([])
  const { data: apiCountries } = useGetCountries()

  const getCountryId = (code?: string | null) => {
    if (code) return apiCountries ? apiCountries.find((country) => country.alpha2 === code)?.['@id'] : undefined
    return
  }

  const getCountryCode = (iri?: string | null) => {
    if (iri) return apiCountries ? apiCountries.find((country) => country['@id'] === iri)?.alpha2 : undefined
    return
  }
  useEffect(() => {
    setCountryOptions(
      apiCountries?.map((country) => ({
        label: getName(country.alpha2!, i18n.language) ?? country.name,
        value: country.alpha2
      })) || []
    )
  }, [i18n, apiCountries])
  return { countryOptions, getCountryCode, getCountryId }
}

export const useLitigationCategoriesOptions = () => {
  const { data: categories } = useGetLitigationCategories({})

  const litigationCategoryOptions =
    categories?.map((category) => ({ label: `${category.name}`, value: `${category['@id']}` })) ?? []

  function getAvailableOrderLitigationCategories(order: GetOrderByIdResponse) {
    const registeredLitigations: Array<string> = order.litigations!.map((el) => el.category!['@id']!)
    return litigationCategoryOptions.filter(
      (option) => registeredLitigations.findIndex((litigation) => litigation === option.value) < 0
    )
  }

  return {
    litigationCategoryOptions,
    getStockStateFilter: (id: string, value?: string) => ({
      id,
      label: getFilterLabel(litigationCategoryOptions, value)
    }),
    getAvailableOrderLitigationCategories
  }
}

export const useShippingStatusOptions = () => {
  const { t } = useTranslation()
  const shippingStatusOptions = [
    { label: t('common.select.shippingStatus.noSent'), value: 'notSent' },
    { label: t('common.select.shippingStatus.sent'), value: 'sent' }
  ]

  const getShippingStatusQueryParams = (value?: string) => {
    return { productSent: value === 'sent' }
  }

  return {
    shippingStatusOptions,
    getShippingStatusFilter: (id: string, value?: string) => ({
      id,
      label: getFilterLabel(shippingStatusOptions, value)
    }),
    getShippingStatusQueryParams
  }
}

export const useOrderSecondaryStatusOptions = () => {
  const { t } = useTranslation()

  const getStatusTraduction = (status: OrderProgress) => {
    const traductions = {
      [OrderProgress.ExcludedPrint]: t('common.select.order.orderSecondaryStatus.excludedPrint'),
      [OrderProgress.CardPreparedComplete]: t('common.select.order.orderSecondaryStatus.cardAssembledComplete'),
      [OrderProgress.CardPreparedIncomplete]: t('common.select.order.orderSecondaryStatus.cardAssembledIncomplete'),
      [OrderProgress.CardCheckedComplete]: t('common.select.order.orderSecondaryStatus.cardCheckedComplete'),
      [OrderProgress.CardCheckedIncomplete]: t('common.select.order.orderSecondaryStatus.cardCheckedIncomplete'),
      [OrderProgress.CardPreorder]: t('common.select.order.orderSecondaryStatus.cardPreorder'),
      [OrderProgress.CardSav]: t('common.select.order.orderSecondaryStatus.cardSav'),
      [OrderProgress.ProductPreparedComplete]: t('common.select.order.orderSecondaryStatus.productAssembledComplete'),
      [OrderProgress.ProductPreparedIncomplete]: t(
        'common.select.order.orderSecondaryStatus.productAssembledIncomplete'
      ),
      [OrderProgress.ProductPreorder]: t('common.select.order.orderSecondaryStatus.productPreorder'),
      [OrderProgress.ProductCheckedComplete]: t('common.select.order.orderSecondaryStatus.productCheckedComplete'),
      [OrderProgress.ProductCheckedIncomplete]: t('common.select.order.orderSecondaryStatus.productCheckedIncomplete'),
      [OrderProgress.ProductSav]: t('common.select.order.orderSecondaryStatus.productSav'),
      [OrderProgress.CardInTransit]: t('common.select.order.orderSecondaryStatus.cardInTransit'),
      [OrderProgress.ProductInTransit]: t('common.select.order.orderSecondaryStatus.productInTransit')
    }

    return traductions[status]
  }

  const orderSecondaryStatusOptions = Object.values(OrderProgress).map((value) => ({
    value,
    label: getStatusTraduction(value)
  }))

  const getSecondaryStatusQueryParams = (status?: OrderProgress): FetchOrdersQueryParams => {
    if (status === OrderProgress.ExcludedPrint) return { excludedFromPrints: true }
    if (status === OrderProgress.ProductInTransit) return { productInTransit: true }
    if (status === OrderProgress.CardInTransit) return { cardInTransit: true }
    if (status === OrderProgress.CardPreparedComplete) return { cardPreparedSecondaryStatus: 'complete' }
    if (status === OrderProgress.CardPreparedIncomplete) return { cardPreparedSecondaryStatus: 'incomplete' }
    if (status === OrderProgress.CardCheckedComplete) return { cardCheckedSecondaryStatus: 'complete' }
    if (status === OrderProgress.CardCheckedIncomplete) return { cardCheckedSecondaryStatus: 'incomplete' }
    if (status === OrderProgress.CardSav) return { cardCheckedSecondaryStatus: 'afterSales' }
    if (status === OrderProgress.CardPreorder) return { cardCheckedSecondaryStatus: 'preorder' }
    if (status === OrderProgress.ProductPreparedComplete) return { productPreparedSecondaryStatus: 'complete' }
    if (status === OrderProgress.ProductPreparedIncomplete) return { productPreparedSecondaryStatus: 'incomplete' }
    if (status === OrderProgress.ProductCheckedComplete) return { productCheckedSecondaryStatus: 'complete' }
    if (status === OrderProgress.ProductCheckedIncomplete) return { productCheckedSecondaryStatus: 'incomplete' }
    if (status === OrderProgress.ProductSav) return { productCheckedSecondaryStatus: 'afterSales' }
    if (status === OrderProgress.ProductPreorder) return { productCheckedSecondaryStatus: 'preorder' }
    return {}
  }

  return {
    orderSecondaryStatusOptions,
    getOrderSecondaryStatusFilter: (id: string, value?: string) => ({
      id,
      label: getFilterLabel(orderSecondaryStatusOptions, value)
    }),
    getStatusTraduction,
    getSecondaryStatusQueryParams
  }
}

export const useOrderStatusOptions = () => {
  const { t } = useTranslation()

  const getStatusTraduction = useCallback(
    (status?: string) => {
      return {
        [OrderStatus.Validated]: t('common.select.order.orderStatus.validated'),
        [OrderStatus.Paid]: t('common.select.order.orderStatus.paid'),
        [OrderStatus.InPreparation]: t('common.select.order.orderStatus.inPreparation'),
        [OrderStatus.Send]: t('common.select.order.orderStatus.send'),
        [OrderStatus.Ready]: t('common.select.order.orderStatus.ready'),
        [OrderStatus.CanceledRequested]: t('common.select.order.orderStatus.canceledRequest'),
        [OrderStatus.Canceled]: t('common.select.order.orderStatus.canceled'),
        [OrderStatus.Treated]: t('common.select.order.orderStatus.treated')
      }[status ?? '']
    },
    [t]
  )

  const orderStatusOptions: Option[] = useMemo(
    () =>
      Object.values(OrderStatus)
        .filter((value) => value !== OrderStatus.Cart)
        .map((value) => ({
          value,
          label: getStatusTraduction(value) ?? value
        })),
    [getStatusTraduction]
  )
  const getOrderStatusQueryParams = (value?: string) => {
    if (value === OrderStatus.CanceledRequested) {
      // We want all order that has been cancelRequested and not canceled
      // Exclude OrderStatus.CanceledRequested cause it's not a real status
      return {
        cancelRequested: true,
        'status[]': Object.values(OrderStatus).filter(
          (status) => ![OrderStatus.CanceledRequested, OrderStatus.Canceled].includes(status)
        )
      }
    }

    return { status: value }
  }

  const getOrderStatusesQueryParams = (value?: string[]) => {
    return { 'status[]': value }
  }

  const getOrderStatusFilter = useCallback(
    (id: string, value?: OrderStatus | OrderStatus[]): Filter[] => {
      const orderStatuses = Array.isArray(value) ? value : [value]

      if (orderStatuses.length)
        return orderStatuses.map((status) => ({
          id,
          label: getFilterLabel(orderStatusOptions, status),
          value: status,
          filterId: `status-${status}`
        }))
      else return [{ id: 'status', label: '' }]
    },
    [orderStatusOptions]
  )

  return {
    orderStatusOptions,
    getOrderStatusFilter,
    getOrderStatusQueryParams,
    getOrderStatusesQueryParams,
    getStatusTraduction
  }
}

export const useStockStatusOptions = () => {
  const { t } = useTranslation()

  const getStatusTraduction = useCallback(
    (status: StockStatus) => {
      const traductions = {
        [StockStatus.Validated]: t('common.select.stock.stockStatus.validated'),
        [StockStatus.Created]: t('common.select.stock.stockStatus.created'),
        [StockStatus.Canceled]: t('common.select.stock.stockStatus.canceled'),
        [StockStatus.Treated]: t('common.select.stock.stockStatus.treated')
      }

      return traductions[status]
    },
    [t]
  )

  const stockStatusOptions: Option[] = Object.values(StockStatus).map((value) => ({
    value,
    label: getStatusTraduction(value)
  }))

  return {
    stockStatusOptions,
    getStatusTraduction,
    getStockStatusFilter: (id: string, value?: string) => ({ id, label: getFilterLabel(stockStatusOptions, value) })
  }
}

export const useShippingTypeOptions = () => {
  const { t } = useTranslation()

  const getShippingTypeTraduction = useCallback(
    (status: ShippingTypeName) => {
      const traductions = {
        [ShippingTypeName.SHOP]: t('common.select.stock.shippingType.shop'),
        [ShippingTypeName.CLASSIC]: t('common.select.stock.shippingType.classic'),
        [ShippingTypeName.FAST]: t('common.select.stock.shippingType.fast'),
        [ShippingTypeName.RELAY]: t('common.select.stock.shippingType.relay'),
        [ShippingTypeName.STAND]: t('common.select.stock.shippingType.stand')
      }

      return traductions[status]
    },
    [t]
  )

  const shippingTypeOptions: Option[] = Object.values(ShippingTypeName).map((value) => ({
    value,
    label: getShippingTypeTraduction(value)
  }))

  const getShippingTypeFilter = (id: string, value?: ShippingTypeName | ShippingTypeName[]) => {
    const shippingTypesList = Array.isArray(value) ? value : [value]
    return shippingTypesList.map((shippingType) => ({
      id,
      label: getFilterLabel(shippingTypeOptions, shippingType),
      value: shippingType,
      filterId: `shippingType-${shippingType}`
    }))
  }

  return {
    shippingTypeOptions,
    getShippingTypeTraduction,
    getShippingTypeFilter
  }
}

export const useFamilyFilters = (params: FetchRangesQueryParams) => {
  const { data: ranges } = useQuery(['fetchRanges', params], () => fetchRanges(params))
  const familyOptions =
    ranges?.map((range) => ({
      label: range.name!,
      value: range['@id']!
    })) ?? []

  return {
    familyOptions,
    getFamilyFilter: (id: string, value?: string) => ({ id, label: getFilterLabel(familyOptions, value) })
  }
}

export const useFidelityCategoriesOptions = () => {
  const { t } = useTranslation()
  const getCategoryTraduction = (category: FidelityPointCategory) =>
    ({
      [FidelityPointCategory.Buy]: t('common.select.fidelityCategories.buy'),
      [FidelityPointCategory.OldFidelityCard]: t('common.select.fidelityCategories.oldFidelityCard'),
      [FidelityPointCategory.Contest]: t('common.select.fidelityCategories.contest'),
      [FidelityPointCategory.CreateAccount]: t('common.select.fidelityCategories.createAccount'),
      [FidelityPointCategory.Sponsorship]: t('common.select.fidelityCategories.sponsorship'),
      [FidelityPointCategory.RetroactiveCode]: t('common.select.fidelityCategories.retroactiveCode'),
      [FidelityPointCategory.EventSubscription]: t('common.select.fidelityCategories.eventSubscription'),
      [FidelityPointCategory.CommercialGesture]: t('common.select.fidelityCategories.commercialGesture'),
      [FidelityPointCategory.CustomerReviews]: t('common.select.fidelityCategories.customerReviews')
    }[category])

  const fidelityCategoriesOptions: Option[] = Object.values(FidelityPointCategory)
    .map((value) => ({
      value,
      label: getCategoryTraduction(value)
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return {
    fidelityCategoriesOptions,
    getCategoryTraduction
  }
}

export const useVoucherCategoriesOptions = () => {
  const { t } = useTranslation()

  const getVoucherCategoryTraduction = useCallback(
    (category: VoucherCategoryName) => {
      return (
        {
          [VoucherCategoryName.CommercialGesture]: t('common.vouchers.commercialGesture'),
          [VoucherCategoryName.CreditStore]: t('common.vouchers.creditStore'),
          [VoucherCategoryName.Event]: t('common.vouchers.event'),
          [VoucherCategoryName.FidelityPoint]: t('common.vouchers.fidelityPoint'),
          [VoucherCategoryName.GiftCard]: t('common.vouchers.giftCard'),
          [VoucherCategoryName.LoyaltyCard]: t('common.vouchers.loyaltyCard'),
          [VoucherCategoryName.OrderCommercialGesture]: t('common.vouchers.orderCommercialGesture'),
          [VoucherCategoryName.Refund]: t('common.vouchers.refund'),
          [VoucherCategoryName.RentalDiscount]: t('common.vouchers.rentalDiscount')
        }[category!] || category
      )
    },
    [t]
  )

  const { data: voucherCategories } = useQuery('voucher-categories', () =>
    fetchVoucherCategories({ onlyInOrder: false })
  )

  const voucherCategoriesOptions: Option[] =
    voucherCategories?.map((category) => ({
      value: category['@id'],
      label: getVoucherCategoryTraduction(category.codeName!)
    })) ?? []

  const getVoucherDaysExpired = useCallback(
    (category?: VoucherCategoryName): number | undefined => {
      return voucherCategories?.find((e) => e['@id'] === category)?.daysExpired
    },
    [voucherCategories]
  )

  return { voucherCategoriesOptions, getVoucherCategoryTraduction, getVoucherDaysExpired }
}

export const useEventStatusOptions = () => {
  const { t } = useTranslation()
  const getEventStatusLabel = useCallback(
    (status: EventStatus | CustomStoreEventStatus) =>
      ({
        [EventStatus.Canceled]: t('common.eventStatus.canceled'),
        [EventStatus.Created]: t('common.eventStatus.created'),
        [EventStatus.Treated]: t('common.eventStatus.treated'),
        [EventStatus.WaitingVoucher]: t('common.eventStatus.waitingVoucher'),
        [CustomStoreEventStatus.Current]: t('common.eventStatus.current')
      }[status]),
    [t]
  )

  const eventStatusOptions = StoreEventStatusOptions.map((value) => ({
    value,
    label: getEventStatusLabel(value)
  }))

  return { eventStatusOptions, getEventStatusLabel }
}

export const useProductCategoriesOptions = (
  params?: FetchProductCategoriesParams,
  valueKey?: string,
  options?: PaginatedProductCategoriesOptions
) => {
  const {
    data: productCategories,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading
  } = usePaginatedProductCategories(['productCategories', params], params, options)

  const productCategoriesOptions: Option[] = productCategories.map((category) => ({
    value: category[valueKey ?? '@id'],
    label: category.name ?? ''
  }))

  return {
    productCategoriesOptions,
    productCategoriesOptionsPagination: { isFetchingNextPage, hasNextPage, fetchNextPage, isLoading }
  }
}

export const useProductSubcategoriesOptions = (parentCategory?: string, valueKey?: string) => {
  const { data: productCategory, isLoading } = useProductCategoryById(parentCategory)
  const productSubcategoriesOptions: Option[] = (productCategory?.children ?? []).map((category) => ({
    value: category[valueKey ?? '@id'],
    label: category.name ?? ''
  }))

  return {
    productSubcategoriesOptions,
    productSubcategoriesOptionsPagination: { isLoading }
  }
}
