import { FetchSealedProductsQueryParams } from 'api/sealedProducts'
import { endOfDay } from 'date-fns'
import { useState } from 'react'
import { UseFormWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SealedProduct } from 'types/entities'

export type PreorderSearchFormInputs = {
  edition: string | null
  language: string | null
  releaseDate: string | null
  searchProduct: string | null
  includeTCG: boolean | null
  selectedProducts: Array<SealedProduct>
}

export default function useOtherProductsSearchFilter(watch: UseFormWatch<PreorderSearchFormInputs>) {
  const { t } = useTranslation()
  const langOptions = [
    { value: 'FR', label: t('common.languages.french') },
    { value: 'EN', label: t('common.languages.english') }
  ]

  const [queryParams, setQueryParams] = useState<FetchSealedProductsQueryParams>()

  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  watch((data) => {
    const queryParams: FetchSealedProductsQueryParams = {
      'lang.code': data.language ?? undefined,
      linkedEdition: data.edition ?? undefined,
      'family.tcg': data.includeTCG === false ? false : undefined,
      'releasedAt[after]': data.releaseDate ? endOfDay(tomorrow).toLocaleDateString('en-EN') : undefined,
      'releasedAt[before]': data.releaseDate
        ? endOfDay(new Date(data.releaseDate)).toLocaleDateString('en-EN')
        : undefined
    }

    setQueryParams(queryParams)
  })

  return {
    queryParams,
    langOptions
  }
}
