import { API_ROUTES } from 'constants/configs'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey } from 'react-query'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

export type FetchRangesQueryParams = Api.GetFamilies.RequestQuery
export const getRangesUri = (params: FetchRangesQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.ranges.root, queryString].join('?')
}

type FetchRangesResponse = Api.GetFamilies.ResponseBody
export const fetchRanges = async (params: FetchRangesQueryParams) => {
  let res = await playInApi.get<FetchRangesResponse>(getRangesUri(params))
  return res.data['hydra:member']
}

export const usePaginatedRanges = (queryKey: QueryKey, params: FetchRangesQueryParams) =>
  usePaginatedQuery<FetchRangesResponse['hydra:member'][0]>(queryKey, getRangesUri(params))

export enum FamilyStatus {
  Hidden = 0,
  OnlyBackoffice = 1,
  FrontWoHomePage = 2,
  FrontWithHomePage = 3
}

export enum FamilyName {
  Magic = 'magic'
}
