import { patchOrderEntry, UpdateOrderEntryBody } from 'api/orderEntries'
import { GetOrderByIdResponse } from 'api/orders'
import ActionButtons from 'app/OrderDetails/components/ActionButtons/ActionButtons'
import { useOrderContext } from 'app/OrderDetails/helper'
import CreditNoteModal from 'app/OrderDetails/modals/CreditNoteModal'
import DeleteEntryModal from 'app/OrderDetails/modals/DeleteEntryModal'
import RefundModal from 'app/OrderDetails/modals/RefundModal'
import SplitOrderModal from 'app/OrderDetails/modals/SplitOrderModal'
import StockErrorModal from 'app/OrderDetails/modals/StockErrorModal'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox.styles'
import FollowMouseContent from 'components/FollowMouseContent/FollowMouseContent'
import ApiResponseIcon from 'components/Icon/ApiResponseIcon'
import { FamilyIcon } from 'components/Icon/Icon.styles'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import Svg from 'components/Svg/Svg'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { DeleteIcon, EditIcon, PlanningIcon, RefundIcon, SplitIcon, WarningIcon } from 'constants/icons'
import CanAccess from 'features/Permissions/CanAccess'
import Link from 'features/Permissions/Link/Link'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import ShouldHide from 'features/Permissions/ShouldHide'
import { useCardFullName } from 'hooks/entityHooks/cardHooks'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { RouteName, useCheckMatch } from 'permissions/permissions'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { OrderEntry } from 'types/entities'
import { CanceledOrderStatuses, UnmodifiableOrderStatuses } from 'types/enums'
import {
  CardProductProductReadProductStats,
  OrderStatus,
  RoleAction,
  SealedProductProductRead
} from 'types/playInApiInterfaces'
import { formatCalendarDate } from 'utils/dates'
import {
  ActionContainer,
  FullSizeMultiLinesTooltip,
  GiftDisclaimer,
  GripProductsInPack,
  Image,
  ProductImageContainer,
  RowContainer,
  RowWithSeparator,
  StrokePrice,
  StyledCell,
  StyledListing,
  StyledLoader,
  UpdatePrice,
  UpdateQuantity
} from './OrderEntriesTable.styles'
import OrderEntryCardForm from './OrderEntryCardForm'
import OrderEntryProductForm from './OrderEntryProductForm'

type Props = {
  order?: GetOrderByIdResponse
  entriesLoading: boolean
}

type PatchOrderEntryPayload = {
  id: number
  body: UpdateOrderEntryBody
}

export type GetCellPropertiesFn = (orderEntry: OrderEntry) => {
  isPreorder: boolean | null
  dateLabel: string | undefined
  classname: string
}

const OrderEntriesTable = ({ order, entriesLoading }: Props) => {
  const { t } = useTranslation()
  const { checkMatch } = useCheckMatch()
  const queryClient = useQueryClient()
  const { getCardFullName } = useCardFullName()
  const orderEntries = queryClient.getQueryData<OrderEntry[]>(['orderEntries', order?.id!]) || []

  const [selectedEntriesId, setSelectedEntriesId] = useState<string[]>([])
  const [productImage, setProductImage] = useState<string | undefined>(undefined)
  const [isProductImageLoaded, setIsProductImageLoaded] = useState(false)
  const [splitModalOpen, toggleSplitModal] = useState(false)
  const [deleteEntryModalOpen, toggleDeleteEntryModal] = useState(false)
  const [refundModalOpen, toggleRefundModal] = useState(false)
  const [creditNoteModalOpen, toggleCreditNoteModal] = useState(false)
  const [stockErrorModalOpen, toggleStockErrorModal] = useState(false)

  const { inMyStoresOrder } = useOrderContext()

  const [showInputIcon, setShowInputIcon] = useState<string | null>()
  useEffect(() => {
    const timeout = setTimeout(() => setShowInputIcon(null), 5000)
    return () => clearTimeout(timeout)
  }, [showInputIcon])

  const {
    mutateAsync: updateOrderEntry,
    isError,
    isLoading,
    isSuccess
  } = useMutation(({ id, body }: PatchOrderEntryPayload) => patchOrderEntry(id, body), {
    onSuccess: (updatedOrderEntry) => {
      queryClient.setQueriesData<OrderEntry[]>(['orderEntries', order?.id!], (prevEntries) => {
        return (prevEntries || []).map((entry) => (entry.id === updatedOrderEntry.id ? updatedOrderEntry : entry))
      })
    }
  })

  const sortedOrderEntries = _.orderBy(orderEntries, ['product.@type', 'product.name'], ['desc', 'asc'])

  const lastSealedProduct = _.chain(sortedOrderEntries)
    .filter((entry) => entry.product?.['@type'] === 'SealedProduct')
    .last()
    .value()

  const getReleaseDate = (date?: Date, approxDate?: string | null) => {
    if (approxDate) {
      return t('page.order.detail.orderEntries.releaseDateApprox', {
        date: approxDate
      })
    }

    if (date) {
      return t('page.order.detail.orderEntries.releaseDate', {
        date: formatCalendarDate(date)
      })
    }
  }

  const getRestockDate = (date?: Date, approxDate?: string | null) => {
    if (approxDate) {
      return t('page.order.detail.orderEntries.restockDateApprox', {
        date: approxDate
      })
    }

    if (date) {
      return t('page.order.detail.orderEntries.restockDate', { date: formatCalendarDate(date) })
    }
  }

  const handleProductDate = (orderEntry: OrderEntry) => {
    const now = new Date()
    const releaseDate = orderEntry?.product?.releasedAt ? new Date(orderEntry.product.releasedAt) : null
    const restockDate = orderEntry?.restockAt ? new Date(orderEntry?.restockAt) : null

    let dateLabel: string | undefined

    if (releaseDate && restockDate) {
      if (restockDate >= releaseDate) {
        dateLabel = getRestockDate(restockDate, orderEntry?.approximateRestockDate)
      } else {
        dateLabel = getReleaseDate(releaseDate, orderEntry?.product?.approximateReleaseDate)
      }
    } else if (restockDate) {
      dateLabel = getRestockDate(restockDate, orderEntry?.approximateRestockDate)
    } else if (releaseDate) {
      dateLabel = getReleaseDate(releaseDate, orderEntry?.product?.approximateReleaseDate)
    }

    return {
      isPreorder: (releaseDate && releaseDate > now) || (restockDate && restockDate > now),
      dateLabel
    }
  }

  const getCellProperties = (orderEntry: OrderEntry) => {
    const dateProperties = handleProductDate(orderEntry)

    return {
      classname: classnames({
        //Add separator on last sealedProduct item, only if there's also CardProduct in order
        'product-separation':
          orderEntry.id === lastSealedProduct?.id &&
          orderEntries?.find((entry) => entry?.product?.['@type'] === 'CardProduct'),
        preorder: dateProperties.isPreorder
      }),
      ...dateProperties
    }
  }

  const allEntitiesSelected = selectedEntriesId.length && selectedEntriesId.length === sortedOrderEntries.length

  const handleSelectAll = () => {
    if (allEntitiesSelected) setSelectedEntriesId([])
    else setSelectedEntriesId(orderEntries?.map((e) => e['@id']!))
  }

  const disableActions = UnmodifiableOrderStatuses.includes(order?.status!)
  const canEditOrderContent = (
    checkMatch([RoleAction.ROLE_ACTION_ORDER_EDIT_CONTENTS_PAID])
      ? [OrderStatus.Validated, OrderStatus.Paid, OrderStatus.InPreparation, OrderStatus.Send, OrderStatus.Ready]
      : [OrderStatus.Validated]
  ).includes(order?.status!)

  const tableEditionRoles = useMemo(
    () => [
      order?.status === OrderStatus.Paid
        ? RoleAction.ROLE_ACTION_ORDER_EDIT_CONTENTS_PAID
        : RoleAction.ROLE_ACTION_ORDER_EDIT_CONTENTS,
      RoleAction.ROLE_ACTION_ORDER_SPLIT_CONTENTS,
      RoleAction.ROLE_ACTION_ORDER_INVENTORY_ERROR_CONTENTS,
      RoleAction.ROLE_ACTION_ORDER_REFUND_CONTENTS
    ],
    [order?.status]
  )

  const columns: Column<OrderEntry>[] = [
    {
      key: 'checkbox',
      decorator: (orderEntry) => {
        const attributs = getCellProperties(orderEntry)

        return (
          <StyledCell className={attributs.classname}>
            <ShouldHide permissions={tableEditionRoles} deniedExtraCondition={!inMyStoresOrder}>
              <Checkbox
                className={classnames({ checked: selectedEntriesId?.includes(orderEntry['@id']!) })}
                onClick={() => setSelectedEntriesId((prev) => _.xor(selectedEntriesId, [orderEntry['@id']!]))}
              />
            </ShouldHide>
            {attributs.isPreorder && <Svg svg={PlanningIcon} size="2.5rem" title={attributs.dateLabel} />}
          </StyledCell>
        )
      }
    },
    {
      key: 'icon',
      decorator: (orderEntry) => (
        <StyledCell className={getCellProperties(orderEntry).classname}>
          <FamilyIcon src={orderEntry?.product?.family?.iconUrl!} title={orderEntry?.product?.family?.name} />
        </StyledCell>
      )
    },
    {
      key: 'quantity',
      name: t('common.label.quantity'),
      decorator: (orderEntry: OrderEntry) => {
        return (
          <StyledCell className={getCellProperties(orderEntry).classname}>
            <CanAccess
              permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_CONTENTS]}
              deniedExtraCondition={!canEditOrderContent || !inMyStoresOrder}
              allowedComponent={
                <RowContainer>
                  <UpdateQuantity
                    type="number"
                    max={Math.max(orderEntry.productStore?.quantity || 0, 0) + orderEntry.quantity!}
                    displayError={false}
                    onSubmit={async ({ inputValue }) => {
                      setShowInputIcon(`${orderEntry.id} quantity`)
                      try {
                        await updateOrderEntry({
                          id: orderEntry.id!,
                          body: { quantity: parseInt(inputValue) }
                        })
                      } catch (error) {
                        return orderEntry.quantity
                      }
                    }}
                    defaultValue={orderEntry.quantity}
                  />
                  <Text fontStyle="italic" fontWeight="light">
                    ({orderEntry.productStore?.quantity})
                  </Text>
                  <ApiResponseIcon
                    showIcon={showInputIcon === `${orderEntry.id} quantity`}
                    isLoading={isLoading}
                    isError={isError}
                    isSuccess={isSuccess}
                  />
                </RowContainer>
              }
              deniedComponent={
                <RowContainer>
                  <Text>{orderEntry.quantity}</Text>
                  <Text fontStyle="italic" fontWeight="light">
                    ({orderEntry.productStore?.quantity})
                  </Text>
                </RowContainer>
              }
            />
          </StyledCell>
        )
      }
    },
    {
      key: 'cardOrProduct',
      name: t('common.label.cardOrProduct'),
      decorator: (orderEntry) => {
        if (orderEntry.product?.['@type'] === 'CardProduct') {
          const cardProduct = orderEntry.product as CardProductProductReadProductStats

          return (
            <StyledCell
              className={getCellProperties(orderEntry).classname}
              onMouseEnter={() => {
                setIsProductImageLoaded(false)
                setProductImage(
                  cardProduct.cardData?.declination?.scan?.imgRecto || cardProduct.cardData?.variation?.imageUrl
                )
              }}
              onMouseLeave={() => {
                setIsProductImageLoaded(false)
                setProductImage(undefined)
              }}
            >
              {getCardFullName(cardProduct)}
            </StyledCell>
          )
        } else {
          const sealedProduct = orderEntry.product as SealedProductProductRead
          return (
            <RowWithSeparator className={getCellProperties(orderEntry).classname}>
              {sealedProduct?.pack && (
                <FullSizeMultiLinesTooltip
                  id={`order-pack-${orderEntry.id}`}
                  title={t('page.order.detail.orderEntries.pack.tooltip')}
                  lines={(orderEntry?.childPack ?? []).map((productInPack) => (
                    <Link route={RouteName.ProductDetails} value={productInPack?.product?.id}>
                      <GripProductsInPack>
                        <Text>{productInPack?.quantity}</Text>
                        <Text>x</Text>
                        <Text>{productInPack?.product?.name}</Text>
                      </GripProductsInPack>
                    </Link>
                  ))}
                />
              )}
              <ClickableCell
                route={RouteName.ProductDetails}
                value={sealedProduct?.id}
                onMouseEnter={() => {
                  setIsProductImageLoaded(false)
                  setProductImage(sealedProduct?.absoluteMainImageUrl || undefined)
                }}
                onMouseLeave={() => {
                  setIsProductImageLoaded(false)
                  setProductImage(undefined)
                }}
              >
                <Text>{sealedProduct?.name}</Text>
              </ClickableCell>
            </RowWithSeparator>
          )
        }
      }
    },
    {
      key: 'lang',
      name: t('common.label.lang'),
      decorator: (orderEntry) => {
        const langCode =
          orderEntry.product?.['@type'] === 'CardProduct'
            ? (orderEntry.product as CardProductProductReadProductStats)?.cardData?.declination?.lang?.code
            : (orderEntry.product as SealedProductProductRead)?.lang?.code

        return (
          <StyledCell className={getCellProperties(orderEntry).classname}>{langCode?.toLocaleUpperCase()}</StyledCell>
        )
      }
    },
    {
      key: 'unitPrice',
      name: t('common.label.unitPrice'),
      decorator: (orderEntry) => (
        <StyledCell className={getCellProperties(orderEntry).classname}>
          <CanAccess
            permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_CONTENTS]}
            deniedExtraCondition={!canEditOrderContent || !inMyStoresOrder}
            allowedComponent={
              <>
                <UpdatePrice
                  type="formattedNumber"
                  onSubmit={async ({ inputValue }) => {
                    setShowInputIcon(`${orderEntry.id} price`)
                    updateOrderEntry({
                      id: orderEntry.id!,
                      body: { paidPrice: inputValue }
                    })
                  }}
                  defaultValue={orderEntry.paidPrice}
                  suffix=" €"
                  decimalScale={2}
                />
                <ApiResponseIcon
                  showIcon={showInputIcon === `${orderEntry.id} price`}
                  isLoading={isLoading}
                  isError={isError}
                  isSuccess={isSuccess}
                />
              </>
            }
            deniedComponent={<TextNumber value={orderEntry.paidPrice} suffix=" €" decimalScale={2} />}
          />
          {orderEntry.originalPrice !== orderEntry.paidPrice && (
            <TextNumber
              value={orderEntry.originalPrice}
              prefix="("
              suffix=" €)"
              decimalScale={2}
              fontWeight="light"
              ContainerComponent={StrokePrice}
            />
          )}
        </StyledCell>
      )
    },
    {
      key: 'totalPrice',
      name: t('common.label.totalUnitPrice'),
      decorator: (orderEntry) => (
        <StyledCell className={getCellProperties(orderEntry).classname}>
          <TextNumber value={orderEntry.paidPrice! * orderEntry.quantity!} suffix=" €" decimalScale={2} />
        </StyledCell>
      )
    }
  ]

  const disableActionButtons = selectedEntriesId.length === 0 || disableActions

  const isRefundDisabled =
    selectedEntriesId.length === 0 ||
    CanceledOrderStatuses.includes(order?.status!) ||
    !order?.paymentReceived ||
    order?.treated ||
    order?.productSent

  const selectedEntries = sortedOrderEntries?.filter((e) => selectedEntriesId.includes(e['@id']!))

  return (
    <CardLayout>
      <Title2>
        {t('page.order.detail.orderEntries.title', {
          count: orderEntries.reduce((total, entry) => total + (entry?.quantity || 1), 0)
        })}
      </Title2>
      <ActionContainer>
        {order?.entries?.length ? (
          <RowContainer>
            <ShouldHide permissions={tableEditionRoles} deniedExtraCondition={!inMyStoresOrder}>
              <Checkbox onClick={handleSelectAll} className={allEntitiesSelected ? 'checked' : ''} />
            </ShouldHide>

            <TooltipHover id="refund-entries" content={<Text>{t('page.order.detail.modals.splitOrder.button')}</Text>}>
              <ShouldDisable
                permissions={[RoleAction.ROLE_ACTION_ORDER_SPLIT_CONTENTS]}
                deniedExtraCondition={!inMyStoresOrder}
              >
                <Button
                  shape="circle"
                  variant="white"
                  icon={SplitIcon}
                  onClick={() => toggleSplitModal(true)}
                  disabled={disableActionButtons || order?.invoiced}
                />
              </ShouldDisable>
            </TooltipHover>

            <TooltipHover id="stock-error" content={<Text>{t('page.order.detail.modals.stockError.tooltip')}</Text>}>
              <ShouldDisable
                permissions={[RoleAction.ROLE_ACTION_ORDER_INVENTORY_ERROR_CONTENTS]}
                deniedExtraCondition={!inMyStoresOrder}
              >
                <Button
                  shape="circle"
                  variant="white"
                  icon={WarningIcon}
                  disabled={disableActionButtons || order?.invoiced}
                  onClick={() => toggleStockErrorModal(true)}
                />
              </ShouldDisable>
            </TooltipHover>

            <TooltipHover id="refund-entries" content={<Text>{t('page.order.detail.modals.refund.button')}</Text>}>
              <ShouldDisable
                permissions={[RoleAction.ROLE_ACTION_ORDER_REFUND_CONTENTS]}
                deniedExtraCondition={!inMyStoresOrder}
              >
                <Button
                  variant="white"
                  shape="circle"
                  icon={RefundIcon}
                  disabled={isRefundDisabled || order?.invoiced}
                  onClick={() => toggleRefundModal(true)}
                />
              </ShouldDisable>
            </TooltipHover>

            <TooltipHover id="delete-entries" content={<Text>{t('page.order.detail.modals.deleteEntry.button')}</Text>}>
              <ShouldDisable
                permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_CONTENTS]}
                deniedExtraCondition={!inMyStoresOrder}
              >
                <Button
                  shape="circle"
                  variant="white"
                  icon={DeleteIcon}
                  disabled={disableActionButtons || order?.invoiced}
                  onClick={() => toggleDeleteEntryModal(true)}
                />
              </ShouldDisable>
            </TooltipHover>

            <TooltipHover
              id="creditNote-create"
              content={<Text>{t('page.order.detail.modals.creditNote.button')}</Text>}
            >
              <ShouldDisable
                permissions={[RoleAction.ROLE_ACTION_ORDER_REFUND_CONTENTS]}
                deniedExtraCondition={!inMyStoresOrder}
              >
                <Button
                  variant="white"
                  shape="circle"
                  icon={EditIcon}
                  disabled={!order.invoiced || !order.billNumber}
                  onClick={() => toggleCreditNoteModal(true)}
                />
              </ShouldDisable>
            </TooltipHover>
          </RowContainer>
        ) : (
          <Text color="danger">{t('page.order.detail.orderEntries.table.emptyText')}</Text>
        )}
        {order && <ActionButtons order={order} />}
      </ActionContainer>

      <ShouldHide
        permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_CONTENTS]}
        deniedExtraCondition={disableActions || !inMyStoresOrder}
      >
        <OrderEntryProductForm order={order} />
        {order?.stock?.cardSeller && <OrderEntryCardForm order={order} />}
      </ShouldHide>

      <StyledListing
        data={sortedOrderEntries}
        columns={columns}
        emptyText=""
        totalCount={order?.entries?.length}
        isFetching={entriesLoading}
      />
      {console.log(sortedOrderEntries[0])}
      {order?.coupons?.some(({ coupon }) => !!coupon?.gift) && (
        <GiftDisclaimer fontWeight="bold" fontStyle="italic">
          {t('page.order.detail.gift')}
        </GiftDisclaimer>
      )}
      <FollowMouseContent show={!!productImage}>
        <ProductImageContainer>
          {!isProductImageLoaded && <StyledLoader size="3em" />}
          <Image src={productImage} onLoad={() => setIsProductImageLoaded(true)} />
        </ProductImageContainer>
      </FollowMouseContent>

      <SplitOrderModal
        open={splitModalOpen}
        onClose={() => toggleSplitModal(false)}
        orderId={order?.id!}
        orderEntries={selectedEntries}
        columns={columns}
        getCellProperties={getCellProperties}
      />
      <DeleteEntryModal
        open={deleteEntryModalOpen}
        onClose={() => toggleDeleteEntryModal(false)}
        orderId={order?.id!}
        orderEntries={selectedEntries}
        columns={columns}
        getCellProperties={getCellProperties}
        callback={() => setSelectedEntriesId([])}
      />
      <RefundModal
        open={refundModalOpen}
        onClose={() => toggleRefundModal(false)}
        orderId={order?.id!}
        orderEntries={selectedEntries}
        callback={() => setSelectedEntriesId([])}
        columns={columns}
        getCellProperties={getCellProperties}
      />
      <CreditNoteModal
        open={creditNoteModalOpen}
        onClose={() => toggleCreditNoteModal(false)}
        orderEntries={selectedEntries}
        callback={() => setSelectedEntriesId([])}
        columns={columns}
        getCellProperties={getCellProperties}
        order={order}
      />
      <StockErrorModal
        open={stockErrorModalOpen}
        onClose={() => toggleStockErrorModal(false)}
        orderId={order?.id!}
        orderEntries={selectedEntries}
        columns={columns}
        getCellProperties={getCellProperties}
        callback={() => setSelectedEntriesId([])}
      />
    </CardLayout>
  )
}

export default OrderEntriesTable
