import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { FamilyCardStoryblokStory } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type PostFamilyCardStoryblokStoriesResponse = Api.PostFamilyCardStoryblokStories.ResponseBody
export const postFamilyCardStoryblokStory = async (body: PostFamilyCardStoryblokStoriesResponse) => {
  const res = await playInApi.post<
    PostFamilyCardStoryblokStoriesResponse,
    AxiosResponse<PostFamilyCardStoryblokStoriesResponse>
  >(API_ROUTES.familyCardStoryblokStories.root, body)

  return res.data
}

type FetchFamilyCardStoryblokStoriesResponse = Api.GetFamilyCardStoryblokStories.ResponseBody
export const fetchFamilyCardStoryblokStories = async (): Promise<FamilyCardStoryblokStory[]> => {
  const res = await playInApi.get<FetchFamilyCardStoryblokStoriesResponse>(API_ROUTES.familyCardStoryblokStories.root)

  return res.data['hydra:member']
}

export type DeleteFamilyCardStoryblokStories = Api.DeleteFamilyCardStoryblokStoriesId.RequestBody
export const deleteFamilyCardStoryblokStories = async (id: number | undefined) => {
  const res = await playInApi.delete<DeleteFamilyCardStoryblokStories, AxiosResponse<DeleteFamilyCardStoryblokStories>>(
    `${API_ROUTES.familyCardStoryblokStories.root}/${id}`
  )

  return res.data
}
