import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { ProductCollectionStoryblokStory } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type PostProductCollectionStoryblokStoriesResponse = Api.PostProductCollectionStoryblokStories.ResponseBody
export const postProductCollectionStoryblokStory = async (body: PostProductCollectionStoryblokStoriesResponse) => {
  const res = await playInApi.post<
    PostProductCollectionStoryblokStoriesResponse,
    AxiosResponse<PostProductCollectionStoryblokStoriesResponse>
  >(API_ROUTES.productCollectionStoryblokStories.root, body)

  return res.data
}

type FetchProductCollectionStoryblokStoriesResponse = Api.GetProductCollectionStoryblokStories.ResponseBody
export const fetchProductCollectionStoryblokStories = async (): Promise<ProductCollectionStoryblokStory[]> => {
  const res = await playInApi.get<FetchProductCollectionStoryblokStoriesResponse>(
    API_ROUTES.productCollectionStoryblokStories.root
  )

  return res.data['hydra:member']
}

export type DeleteProductCollectionStoryblokStories = Api.DeleteProductCollectionStoryblokStoriesId.RequestBody
export const deleteProductCollectionStoryblokStories = async (id: number | undefined) => {
  const res = await playInApi.delete<
    DeleteProductCollectionStoryblokStories,
    AxiosResponse<DeleteProductCollectionStoryblokStories>
  >(`${API_ROUTES.productCollectionStoryblokStories.root}/${id}`)

  return res.data
}
