import { ReactComponent as AngleUpIconSvg } from 'assets/svg/icon/angle-up.svg'
import { ReactComponent as ArchiveIconSvg } from 'assets/svg/icon/archive.svg'
import { ReactComponent as ArrowIconSvg } from 'assets/svg/icon/arrow.svg'
import { ReactComponent as BarcodeIconSvg } from 'assets/svg/icon/barcode.svg'
import { ReactComponent as CalculateIconSvg } from 'assets/svg/icon/calculate.svg'
import { ReactComponent as CardIconSvg } from 'assets/svg/icon/card.svg'
import { ReactComponent as CardmarketIconSvg } from 'assets/svg/icon/cardmarket.svg'
import { ReactComponent as CardtraderIconSvg } from 'assets/svg/icon/cardtrader.svg'
import { ReactComponent as CartIconSvg } from 'assets/svg/icon/cart.svg'
import { ReactComponent as CheckIconSvg } from 'assets/svg/icon/check.svg'
import { ReactComponent as CollaboratorIconSvg } from 'assets/svg/icon/collaborator.svg'
import { ReactComponent as CommunicationIconSvg } from 'assets/svg/icon/communication.svg'
import { ReactComponent as CreateIconSvg } from 'assets/svg/icon/create.svg'
import { ReactComponent as CrossIconSvg } from 'assets/svg/icon/cross.svg'
import { ReactComponent as DeleteIconSvg } from 'assets/svg/icon/delete.svg'
import { ReactComponent as DeliveryIconSvg } from 'assets/svg/icon/delivery.svg'
import { ReactComponent as EditIconSvg } from 'assets/svg/icon/edit.svg'
import { ReactComponent as ExportIconSvg } from 'assets/svg/icon/export.svg'
import { ReactComponent as InvoiceIconSvg } from 'assets/svg/icon/facture.svg'
import { ReactComponent as InfoIconSvg } from 'assets/svg/icon/info.svg'
import { ReactComponent as InventoryIconSvg } from 'assets/svg/icon/inventory.svg'
import { ReactComponent as LoaderIconSvg } from 'assets/svg/icon/loader.svg'
import { ReactComponent as LockIconSvg } from 'assets/svg/icon/lock.svg'
import { ReactComponent as MenuClosedSvg } from 'assets/svg/icon/menu-closed.svg'
import { ReactComponent as MenuOpenSvg } from 'assets/svg/icon/menu-open.svg'
import { ReactComponent as MoreIconSvg } from 'assets/svg/icon/more.svg'
import { ReactComponent as PlanningIconSvg } from 'assets/svg/icon/planning.svg'
import { ReactComponent as PlayinPIconSvg } from 'assets/svg/icon/playinp.svg'
import { ReactComponent as PrintIconSvg } from 'assets/svg/icon/print.svg'
import { ReactComponent as ProductIconSvg } from 'assets/svg/icon/product.svg'
import { ReactComponent as QuestionIconSvg } from 'assets/svg/icon/question.svg'
import { ReactComponent as RefundIconSvg } from 'assets/svg/icon/refund.svg'
import { ReactComponent as SaveIconSvg } from 'assets/svg/icon/save.svg'
import { ReactComponent as SearchIconSvg } from 'assets/svg/icon/search.svg'
import { ReactComponent as SellerManiaIconSvg } from 'assets/svg/icon/sellermania.svg'
import { ReactComponent as SendIconSvg } from 'assets/svg/icon/send.svg'
import { ReactComponent as SplitIconSvg } from 'assets/svg/icon/split.svg'
import { ReactComponent as StockIconSvg } from 'assets/svg/icon/stock.svg'
import { ReactComponent as StoreIconSvg } from 'assets/svg/icon/store.svg'
import { ReactComponent as SyncIconSvg } from 'assets/svg/icon/sync.svg'
import { ReactComponent as TimeIconSvg } from 'assets/svg/icon/time.svg'
import { ReactComponent as TransfertIconSvg } from 'assets/svg/icon/transfert.svg'
import { ReactComponent as UserIconSvg } from 'assets/svg/icon/user.svg'
import { ReactComponent as WarehouseIconSvg } from 'assets/svg/icon/warehouse.svg'
import { ReactComponent as WarningIconSvg } from 'assets/svg/icon/warning.svg'
import { ReactComponent as WarningRedIconSvg } from 'assets/svg/icon/warning_red.svg'

export const CircleLoaderIcon = LoaderIconSvg
export const ArchiveIcon = ArchiveIconSvg
export const AngleUpIcon = AngleUpIconSvg
export const BarcodeIcon = BarcodeIconSvg
export const CalculateIcon = CalculateIconSvg
export const CheckIcon = CheckIconSvg
export const DeleteIcon = DeleteIconSvg
export const EditIcon = EditIconSvg
export const ExportIcon = ExportIconSvg
export const LockIcon = LockIconSvg
export const MoreIcon = MoreIconSvg
export const PrintIcon = PrintIconSvg
export const SaveIcon = SaveIconSvg
export const SearchIcon = SearchIconSvg
export const SendIcon = SendIconSvg
export const SplitIcon = SplitIconSvg
export const SyncIcon = SyncIconSvg
export const WarningIcon = WarningIconSvg
export const WarningRedIcon = WarningRedIconSvg
export const ProductIcon = ProductIconSvg
export const UserIcon = UserIconSvg
export const CartIcon = CartIconSvg
export const CreateIcon = CreateIconSvg
export const CrossIcon = CrossIconSvg
export const WarehouseIcon = WarehouseIconSvg
export const CardmarketIcon = CardmarketIconSvg
export const CardtraderIcon = CardtraderIconSvg
export const PlayinPIcon = PlayinPIconSvg
export const SellerManiaIcon = SellerManiaIconSvg
export const MenuOpenIcon = MenuOpenSvg
export const MenuClosedIcon = MenuClosedSvg
export const CardIcon = CardIconSvg
export const PlanningIcon = PlanningIconSvg
export const ArrowIcon = ArrowIconSvg
export const RefundIcon = RefundIconSvg
export const InfoIcon = InfoIconSvg
export const StockIcon = StockIconSvg
export const StoreIcon = StoreIconSvg
export const CollaboratorIcon = CollaboratorIconSvg
export const InventoryIcon = InventoryIconSvg
export const DeliveryIcon = DeliveryIconSvg
export const TimeIcon = TimeIconSvg
export const TransfertIcon = TransfertIconSvg
export const QuestionIcon = QuestionIconSvg
export const InvoiceIcon = InvoiceIconSvg
export const CommunicationIcon = CommunicationIconSvg
