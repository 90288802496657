import { yupResolver } from '@hookform/resolvers/yup'
import Loader from 'components/Loader/Loader'
import { Text } from 'components/Text/Text.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { TitleText } from 'components/TooltipContents/TootipContents.styles'
import { ComponentType } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Container, Icon, StyledButtonLoader, StyledInput } from './InputSearchForm.styles'

export interface SearchForm {
  value: string
}

interface Props {
  svg: ComponentType
  text: string
  title?: string
  isLoading: boolean
  onSearch?: any
  /** Pass DOM attributes to the input element */
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  placeholder?: string
}

const schema = yup.object().shape({
  value: yup.string().trim()
})

export const useInputSearchForm = () => {
  const { register, handleSubmit, reset } = useForm<SearchForm>({
    resolver: yupResolver(schema)
  })

  const InputSearchForm = ({ svg, text, onSearch, isLoading, title, inputProps, placeholder }: Props) => (
    <form onSubmit={handleSubmit(onSearch)}>
      <Container>
        <TooltipHover
          id="inputSearchFormTooltip"
          content={
            <>
              {title && <TitleText fontWeight="medium">{title}</TitleText>}
              <Text fontWeight="light">{text}</Text>
            </>
          }
          container="tooltipContainer"
        >
          {isLoading ? (
            <StyledButtonLoader>
              <Loader />
            </StyledButtonLoader>
          ) : (
            <Icon svg={svg} />
          )}
        </TooltipHover>

        <StyledInput {...register('value')} {...inputProps} placeholder={placeholder} />
      </Container>
    </form>
  )

  return {
    reset,
    InputSearchForm
  }
}
