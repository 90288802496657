import { yupResolver } from '@hookform/resolvers/yup'
import { getCardEditionsUri } from 'api/cardEditions'
import { getCardsUri } from 'api/cards'
import { FamilyName } from 'api/ranges'
import FiltersList from 'components/FiltersList/FiltersList'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import MultipleSelect from 'components/Select/MultipleSelect'
import Select from 'components/Select/Select'
import Textarea from 'components/Textarea/Textarea'
import { Title2 } from 'components/Title/Title.styles'
import { SearchIcon } from 'constants/icons'
import { useCardFamiliesOptions } from 'hooks/entityHooks/cardHooks'
import { getPath, RouteName } from 'permissions/permissions'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { CardEditionJsonldCardEditionRead } from 'types/playInApiInterfaces'
import { SortingDirection } from 'types/sorting'
import { hasOneOfFieldsMethod } from 'utils/formHelper'
import * as yup from 'yup'
import { SearchByNameWrapper, SearchCardFormLayout, SubmitButton, TwoColsGrid } from './CardManagement.style'
import useCardsFilters from './useCardsFilters'

const schema = yup
  .object({
    family: yup.string().trim(),
    text: yup.string().trim(),
    name: yup.string(),
    rarity: yup.array(yup.string().trim().required()),
    edition: yup.string().trim(),
    type: yup.number(),
    pricingTag: yup.number()
  })
  .test(hasOneOfFieldsMethod())

export interface SearchCardInputs extends yup.InferType<typeof schema> {}

interface Props {
  onSubmit: (data: SearchCardInputs) => void
  isLoading?: boolean
  sidebarSearch?: string
}

export default function SearchCardForm({ onSubmit, isLoading, sidebarSearch }: Props) {
  const { t } = useTranslation()

  const [selectedEdition, setSelectedEdition] = useState<CardEditionJsonldCardEditionRead>()

  const {
    handleSubmit,
    formState: { errors, touchedFields },
    register,
    control,
    watch,
    setValue
  } = useForm<SearchCardInputs>({
    resolver: yupResolver(schema)
  })

  const [watchFamily] = watch(['family'])

  const {
    handleFilters,
    filters,
    cardTypesOptions,
    cardTypesOptionsPagination,
    cardRarityOptions,
    cardRarityOptionsPagination,
    pricingTagsOptions
  } = useCardsFilters({
    family: watchFamily,
    selectedEdition
  })

  const { cardFamiliesOptions } = useCardFamiliesOptions({
    onSuccessCallback: (cardFamilies) => {
      const magicFamily = cardFamilies.find((e) => e.codeName === FamilyName.Magic)
      if (magicFamily) setValue('family', `${magicFamily?.id}`)
    }
  })

  const navigate = useNavigate()

  const defaultFilters = [
    ...(sidebarSearch ? [{ id: 'search', label: t('page.order.manage.filters.search', { value: sidebarSearch }) }] : [])
  ]

  const handleForm = (formData: SearchCardInputs) => {
    handleFilters(formData)
    onSubmit(formData)
  }

  useEffect(() => {
    setValue('edition', undefined)
    setValue('rarity', undefined)
    setValue('type', undefined)
  }, [watchFamily, setValue])

  return (
    <CardLayout>
      <Title2>{t('page.card.manage.formTitle')}</Title2>

      <form onSubmit={handleSubmit(handleForm)}>
        <SearchCardFormLayout>
          <TwoColsGrid>
            <Select
              options={cardFamiliesOptions}
              control={control}
              id="family"
              label={t('common.label.range')}
              enableUnselect={true}
              placeholder={t('common.select.defaultOptions.familly')}
            />
            <InputAutoComplete
              control={control}
              isTouched={touchedFields.edition}
              id="edition"
              getUrlFromSearch={(search) =>
                getCardEditionsUri({ search, 'order[name]': SortingDirection.Asc, family: watch('family') })
              }
              labelKey="name"
              valueKey="id"
              label={t('common.label.cardEdition')}
              placeholder={t('page.card.manage.placeholders.cardEdition')}
              onEntitySelected={(entity) => setSelectedEdition(entity)}
              disabled={!watchFamily}
            />

            <MultipleSelect
              options={cardRarityOptions}
              control={control}
              id="rarity"
              label={t('common.label.cardRarity')}
              placeholder={t('page.card.manage.placeholders.cardRarity')}
              disabled={!watchFamily}
              {...cardRarityOptionsPagination}
            />

            <Select
              control={control}
              id="type"
              options={cardTypesOptions}
              label={t('common.label.type')}
              placeholder={t('page.card.manage.placeholders.cardType')}
              enableUnselect={true}
              disabled={!watchFamily}
              {...cardTypesOptionsPagination}
            />

            <Select
              control={control}
              id="pricingTag"
              label={t('common.label.tag')}
              options={pricingTagsOptions}
              placeholder={t('common.select.defaultOptions.tag')}
              enableUnselect={true}
            />

            <SearchByNameWrapper>
              <InputAutoComplete
                id="name"
                control={control}
                isTouched={touchedFields.name}
                getUrlFromSearch={(search) => getCardsUri({ search, 'order[name]': SortingDirection.Asc })}
                labelKey="name"
                valueKey="id"
                onEntitySelected={(entity) =>
                  entity && navigate(getPath(RouteName.CardDetails).replace(':id', entity?.id))
                }
                label={t('common.label.cardName')}
                placeholder={t('page.card.manage.placeholders.cardName')}
              />
            </SearchByNameWrapper>
          </TwoColsGrid>

          <Textarea
            register={register}
            id="text"
            label={t('common.label.cardText')}
            placeholder={t('page.card.manage.placeholders.cardText')}
            rows={11}
          />
        </SearchCardFormLayout>
        <BasicFieldValidationError error={errors['oneOf']} message={t('form.required.oneOf')} />

        <SubmitButton buttonType="submit" icon={SearchIcon} shape="circle" isLoading={isLoading} />
      </form>

      <FiltersList filters={[...defaultFilters, ...filters]} disableClick={true} />
    </CardLayout>
  )
}
