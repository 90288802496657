import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { CategoryStoryblokStory } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type PostCategoryStoryblokStoriesResponse = Api.PostCategoryStoryblokStories.ResponseBody
export const postCategoryStoryblokStory = async (body: PostCategoryStoryblokStoriesResponse) => {
  const res = await playInApi.post<
    PostCategoryStoryblokStoriesResponse,
    AxiosResponse<PostCategoryStoryblokStoriesResponse>
  >(API_ROUTES.categoryStoryblokStories.root, body)

  return res.data
}

type FetchCategoryStoryblokStoriesResponse = Api.GetCategoryStoryblokStories.ResponseBody
export const fetchCategoryStoryblokStories = async (): Promise<CategoryStoryblokStory[]> => {
  const res = await playInApi.get<FetchCategoryStoryblokStoriesResponse>(API_ROUTES.categoryStoryblokStories.root)

  return res.data['hydra:member']
}

export type DeleteCategoryStoryblokStories = Api.DeleteCategoryStoryblokStoriesId.RequestBody
export const deleteCategoryStoryblokStories = async (id: number | undefined) => {
  const res = await playInApi.delete<DeleteCategoryStoryblokStories, AxiosResponse<DeleteCategoryStoryblokStories>>(
    `${API_ROUTES.categoryStoryblokStories.root}/${id}`
  )

  return res.data
}
