import { API_ROUTES } from 'constants/configs'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

export type RewardCategoryPropertiesQueryParams = Api.GetRewardCategories.RequestQuery
export const getRewardCategoriesUri = (params?: RewardCategoryPropertiesQueryParams) => {
  const qs = stringify(params ?? {})
  return [API_ROUTES.rewardCategories.root, qs].join('?')
}

type FetchRewardCategoryResponse = Api.GetRewardCategories.ResponseBody
export const fetchRewardCategories = async () => {
  let res = await playInApi.get<FetchRewardCategoryResponse>(getRewardCategoriesUri())
  return res.data['hydra:member']
}
