import { fetchOrderEntries } from 'api/orderEntries'
import { orderByIdQueryKey, useGetOrderById } from 'api/orders'
import { useOrderShippingModes } from 'api/shippingModes'
import { PageLoader } from 'components/Loader/Loader'
import Tabs, { TabProps } from 'components/Tabs/Tabs'
import MainTitle from 'components/Title/MainTitle'
import CanAccess from 'features/Permissions/CanAccess'
import useAuth from 'hooks/useAuth'
import useShouldReload from 'hooks/useShouldReload'
import { createContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { Params, useParams } from 'react-router-dom'
import { RoleAction } from 'types/playInApiInterfaces'
import { formatDateAndHour } from 'utils/dates'
import BillSection from './components/BillSection/BillSection'
import CommentBox from './components/CommentBox/CommentBox'
import InstructionBox from './components/InstructionBox/InstructionBox'
import OrderInfoCard from './components/OrderInfoCard/OrderInfoCard'
import ShippingSectionCard from './components/ShippingSection/ShippingSection'
import ShippingSectionDisplay from './components/ShippingSection/ShippingSectionDisplay'
import StatusHeader from './components/StatusHeader/StatusHeader'
import OrderCreditNoteEntriesTable from './forms/orderEntriesTable/OrderCreditNoteEntriesTable'
import OrderEntriesTable from './forms/orderEntriesTable/OrderEntriesTable'
import { getRecipientNameFromOrder, useOrderTab } from './helper'
import {
  AddressCardLayout,
  BillingCardLayout,
  HiddenOverflowSection,
  MainCardContainer,
  ShippingCardLayout
} from './OrderDetails.styles'
import MiscTab from './tabs/MiscTab/MiscTab'
import PaymentTab from './tabs/PaymentTab/PaymentTab'
import PreparationTab from './tabs/PreparationTab/PreparationTab'
import PrintTab from './tabs/PrintTab/PrintTab'

interface QueryParams extends Params {
  id: string
}

interface OrderContextProps {
  inMyStoresOrder: boolean
}
export const OrderContext = createContext<OrderContextProps>({
  inMyStoresOrder: false
})

function OrderDetails() {
  const { t } = useTranslation()
  const { id } = useParams() as QueryParams

  useShouldReload(id)

  const { me } = useAuth()
  const queryClient = useQueryClient()

  const { data: order, isFetched } = useGetOrderById(id, {
    onSuccess: (orderResponse) => {
      toggleComment(!!orderResponse?.comment && orderResponse.comment.length > 0)
      toggleInstruction(!!orderResponse?.instruction && orderResponse.instruction.length > 0)
      //On order change, refetch shippingModes
      queryClient.refetchQueries(shippingModeQueryKey)
    }
  })

  const { orderDefaultTab } = useOrderTab(order)

  const { isFetching: entriesLoading, isFetchedAfterMount: orderEntriesHaveBeenFetched } = useQuery(
    // we need to parse id queryparams to int, because we use order.id elsewhere which is an int
    ['orderEntries', Number.parseInt(id)],
    () => fetchOrderEntries({ order: id }),
    {
      onSuccess: () => {
        // Only refetch order after first fetch
        if (orderEntriesHaveBeenFetched) {
          // If orderEntries change, refetch order
          queryClient.refetchQueries(orderByIdQueryKey(order?.id!))
        }
      }
    }
  )
  const { queryKey: shippingModeQueryKey } = useOrderShippingModes(order)

  // Comment and instruction fields are expected to be hidden when empty, but they can be enabled by clicking a button
  // further down the page.
  const [commentEnabled, toggleComment] = useState(!!order?.comment && order.comment.length > 0)
  const [instructionEnabled, toggleInstruction] = useState(!!order?.instruction && order.instruction.length > 0)

  const openCommentField = () => {
    toggleComment(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const openInstructionField = () => {
    toggleInstruction(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const tabs: TabProps[] = [
    {
      tabLabel: t('page.order.detail.tabs.misc.title'),
      panelComponent: (
        <MiscTab order={order!} toggleComment={openCommentField} toggleInstruction={openInstructionField} />
      ),
      noStyle: true
    },
    {
      tabLabel: t('page.order.detail.tabs.payment.title'),
      panelComponent: <PaymentTab order={order} />,
      noStyle: true
    },
    {
      tabLabel: t('page.order.detail.tabs.print.title'),
      panelComponent: <PrintTab order={order} />,
      noStyle: true
    },
    {
      tabLabel: t('page.order.detail.tabs.preparation.title'),
      panelComponent: <PreparationTab order={order} />,
      noStyle: true
    }
  ]

  const inMyStoresOrder = !!me?.stores?.find(
    (e) => e['@id'] === order?.stock?.['@id'] || e['@id'] === order?.address?.relatedStoreAddress?.['@id']
  )

  return (
    <OrderContext.Provider value={{ inMyStoresOrder }}>
      <HiddenOverflowSection>
        <StatusHeader order={order} />
        {isFetched ? (
          <>
            <MainTitle
              title={
                order?.billNumber
                  ? t('page.order.detail.title', { refNum: order?.referenceNumber, billNum: order?.billNumber }) +
                    ' (' +
                    formatDateAndHour(order?.billedAt) +
                    ')'
                  : t('page.order.detail.titleReferenceNumber', { refNum: order?.referenceNumber })
              }
              subtitle={
                order?.litigations && order?.litigations.length > 0
                  ? order?.litigations.map((el) => el?.category?.name).join(', ')
                  : ''
              }
              subTitleColor="danger"
              tabTitle={`${getRecipientNameFromOrder(order)} ${order?.referenceNumber}`}
            />
            {commentEnabled && (
              <CommentBox comment={order?.comment!} orderId={order?.id!} toggleComment={toggleComment} />
            )}
            {instructionEnabled && (
              <InstructionBox
                instruction={order?.instruction!}
                orderId={order?.id!}
                toggleInstruction={toggleInstruction}
              />
            )}
            <MainCardContainer>
              <AddressCardLayout>
                <OrderInfoCard order={order} />
              </AddressCardLayout>
              <ShippingCardLayout>
                <CanAccess
                  permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_SHIPPING_MODE]}
                  allowedComponent={<ShippingSectionCard order={order} />}
                  deniedComponent={<ShippingSectionDisplay order={order} />}
                  deniedExtraCondition={!inMyStoresOrder}
                />
              </ShippingCardLayout>
              <BillingCardLayout>
                <BillSection order={order!} />
              </BillingCardLayout>
            </MainCardContainer>
            <Tabs tabs={tabs} defaultIndex={orderDefaultTab} />
            <OrderEntriesTable order={order} entriesLoading={entriesLoading} />
            <OrderCreditNoteEntriesTable order={order} />
          </>
        ) : (
          <PageLoader />
        )}
      </HiddenOverflowSection>
    </OrderContext.Provider>
  )
}

export default OrderDetails
