import classNames, { default as classnames } from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Cell from 'components/SortedTable/Cell'
import { Column } from 'components/SortedTable/SortedTable'
import { Title2 } from 'components/Title/Title.styles'
import { DeleteIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getArticleManagementQueryKey } from '../form/ArticleManagementForm'
import { ListActions } from './ArticleManagementTable.styles'

type QueryFn<T extends object> = (page: number) => Promise<T[]>

type Props<T extends object> = {
  title: string
  queryFn: QueryFn<T>
  deleteFn: (id: number | undefined) => Promise<void>
  type: string
  mapper: {
    id: (item: T) => number
    label: (item: T) => string
    storyId: (item: T) => number
  }
}

export default function ArticleManagementTable<T extends object>({ title, queryFn, deleteFn, type, mapper }: Props<T>) {
  const { t } = useTranslation()

  const [selectedStoryblokStories, setStoryblokStory] = useState<number[]>([])
  const { data: storyblokStories, refetch } = useQuery(getArticleManagementQueryKey(type), () => queryFn(1))

  const { mutate: deleteLinkStoryblokStoriesMutation, isLoading: deleteLinkStoryblokStoriesMutationLoading } =
    useMutation((id: number | undefined) => deleteFn(id), {
      onSuccess: () => {
        refetch()
      }
    })

  const handleDeleteLinkStoryblokStories = () => {
    selectedStoryblokStories?.map((storyblokStoryId) => deleteLinkStoryblokStoriesMutation(storyblokStoryId))
    setStoryblokStory([])
  }

  const handleSelectAll = () =>
    setStoryblokStory(
      !storyblokStories || storyblokStories.length === selectedStoryblokStories?.length
        ? []
        : storyblokStories.map((storyblokStory) => mapper.id(storyblokStory))
    )

  const columns: Column<T>[] = [
    {
      key: 'checkboxDelete',
      decorator: (storyblokStory) => (
        <Checkbox
          className={classnames({ checked: selectedStoryblokStories?.includes(mapper.id(storyblokStory)) })}
          onClick={() => setStoryblokStory((prev) => _.xor(prev, [mapper.id(storyblokStory)]))}
        />
      )
    },
    {
      key: 'name',
      name: t(title),
      sortable: true,
      decorator: (storyblokStory) => <Cell>{mapper.label(storyblokStory)}</Cell>
    },
    {
      key: 'storyId',
      name: t('page.articleManagement.table.storyId'),
      sortable: true,
      decorator: (storyblokStory) => <Cell>{mapper.storyId(storyblokStory)}</Cell>
    }
  ]

  return (
    <>
      {storyblokStories?.length ? (
        <CardLayout>
          <Title2>{t(title)}</Title2>
          <ListActions>
            <Checkbox
              className={classNames({
                checked:
                  !!selectedStoryblokStories.length && selectedStoryblokStories.length === storyblokStories?.length
              })}
              onClick={() => handleSelectAll()}
            />

            <Button
              icon={DeleteIcon}
              shape="circle"
              variant="white"
              disabled={!selectedStoryblokStories.length}
              onClick={() => handleDeleteLinkStoryblokStories()}
              isLoading={deleteLinkStoryblokStoriesMutationLoading}
            />
          </ListActions>

          <Listing
            data={storyblokStories}
            emptyText={t('page.stores.details.storeManage.listing.ranges.empty')}
            columns={columns}
          />
        </CardLayout>
      ) : null}
    </>
  )
}
