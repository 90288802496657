import styled from 'styled-components'

export const SpinningIcon = styled.span`
  display: flex;
  justify-content: center;

  svg {
    -moz-animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
export const EmptyIcon = styled.div`
  height: 1.4rem;
  width: 1.4rem;
`

export const FamilyIcon = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
`
