import Input from 'components/Input/Input'
import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import Select from 'components/Select/Select'
import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding: 1.25rem 1rem;
  flex-wrap: wrap;

  background-color: ${(props) => props.theme.backgroundColors.lightPurple};
  border-bottom: 2px solid ${(props) => props.theme.borderColors.black};
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    gap: 1em;
  }
`

export const StoryBlokIdInput = styled(Input)`
  max-width: 35rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    max-width: unset;
    width: unset;
    flex: 1 0 auto;
  }
` as typeof Input

export const StoryBlokSelect = styled(Select)`
  max-width: 35rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    max-width: unset;
    width: unset;
    flex: 1 0 auto;
  }
` as typeof Select

export const StoryBlokInputAutoComplete = styled(InputAutoComplete)`
  max-width: 35rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    max-width: unset;
    width: unset;
    flex: 1 0 auto;
  }
` as typeof InputAutoComplete
