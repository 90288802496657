import { yupResolver } from '@hookform/resolvers/yup'
import { getCardEditionsUri } from 'api/cardEditions'
import { postCardEditionStoryblokStory } from 'api/cardEditionStoryblokStories'
import { postCategoryStoryblokStory } from 'api/categoryStoryblokStories'
import { getCollectionsUri } from 'api/collection'
import { postFamilyCardStoryblokStory } from 'api/familyCardStoryblokStories'
import { postFamilyStoryblokStory } from 'api/familyStoryblokStories'
import { getProductCategoriesUri } from 'api/productCategories'
import { postProductCollectionStoryblokStory } from 'api/productCollectionStoryblokStories'
import { getRangesUri } from 'api/ranges'
import { getRewardCategoriesUri } from 'api/rewardCategories'
import { postRewardCategoryStoryblokStory } from 'api/rewardCategoryStoryblokStories'
import { getSealedProductsUri } from 'api/sealedProducts'
import { postSealedProductStoryblokStory } from 'api/sealedProductStoryblokStories'
import Button from 'components/Button/Button'
import { MoreIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { SortingDirection } from 'types/sorting'
import * as yup from 'yup'
import { Form, StoryBlokIdInput, StoryBlokInputAutoComplete, StoryBlokSelect } from './ArticleManagementForm.styles'

const articleFormSchema = yup.object().shape({
  storyBlokId: yup.number().required(),
  type: yup.string().trim().required(),
  linkedEntity: yup.string().trim().required()
})

type ArticleFormData = {
  storyBlokId: number
  type: string
  linkedEntity: string
}

export function getArticleManagementQueryKey(type: string) {
  return [type, 'ArticleManagement']
}

const ArticleManagementForm = () => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    register,
    formState: { touchedFields },
    watch
  } = useForm<ArticleFormData>({
    resolver: yupResolver(articleFormSchema)
  })

  const typeOptions = [
    { value: 'family-cards', label: "Carte d'une gamme" },
    { value: 'product-categories', label: 'Catégorie' },
    { value: 'card-editions', label: 'Édition' },
    { value: 'families', label: 'Gamme' },
    { value: 'sealed-products', label: 'Produit' },
    { value: 'reward-categories', label: 'Récompense de jeu' },
    { value: 'product-collections', label: 'Série de jeu' }
  ]

  const queryClient = useQueryClient()
  const selectedType = watch('type')
  const getOptionsFetcher = useCallback(
    (search) => {
      let getUri

      switch (selectedType) {
        case 'family-cards':
          getUri = getRangesUri({ name: search, cards: true, 'order[name]': SortingDirection.Asc })
          break
        case 'product-categories':
          getUri = getProductCategoriesUri({ name: search, 'order[name]': SortingDirection.Asc })
          break
        case 'card-editions':
          getUri = getCardEditionsUri({ search: search, 'order[name]': SortingDirection.Asc })
          break
        case 'families':
          getUri = getRangesUri({ name: search, 'order[name]': SortingDirection.Asc })
          break
        case 'sealed-products':
          getUri = getSealedProductsUri({ name: search, 'order[name]': SortingDirection.Asc })
          break
        case 'reward-categories':
          getUri = getRewardCategoriesUri({ name: search })
          break
        case 'product-collections':
          getUri = getCollectionsUri({ name: search, 'order[name]': SortingDirection.Asc })
          break
        default:
          return () => {}
      }

      return getUri
    },
    [selectedType]
  )

  const getMutation = (body) => {
    let mutation
    let iri = '/api/' + body.type + '/' + body.linkedEntity

    switch (body.type) {
      case 'family-cards':
        iri = '/api/families/' + body.linkedEntity
        mutation = postFamilyCardStoryblokStory({ storyId: body.storyBlokId, family: iri })
        break
      case 'product-categories':
        mutation = postCategoryStoryblokStory({ storyId: body.storyBlokId, category: iri })
        break
      case 'card-editions':
        mutation = postCardEditionStoryblokStory({ storyId: body.storyBlokId, cardEdition: iri })
        break
      case 'families':
        mutation = postFamilyStoryblokStory({ storyId: body.storyBlokId, family: iri })
        break
      case 'sealed-products':
        mutation = postSealedProductStoryblokStory({ storyId: body.storyBlokId, sealedProduct: iri })
        break
      case 'reward-categories':
        mutation = postRewardCategoryStoryblokStory({ storyId: body.storyBlokId, rewardCategory: iri })
        break
      case 'product-collections':
        mutation = postProductCollectionStoryblokStory({ storyId: body.storyBlokId, productCollection: iri })
        break
      default:
        return () => {}
    }
    return mutation
  }

  const { mutate: addStoryblokStory, isLoading: isMutationLoading } = useMutation(
    (body: ArticleFormData) => getMutation(body),
    {
      onSuccess: () => {
        queryClient.refetchQueries(getArticleManagementQueryKey(selectedType))
      }
    }
  )

  const onSubmit = (formData: ArticleFormData) => {
    addStoryblokStory(formData)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <StoryBlokIdInput
        id="storyBlokId"
        register={register}
        placeholder={`${t('page.articleManagement.placeholder.storyBlokId')}`}
        type="number"
      />

      <StoryBlokSelect
        placeholder={t('page.articleManagement.placeholder.type')}
        control={control}
        id="type"
        options={typeOptions}
      />

      <StoryBlokInputAutoComplete
        getUrlFromSearch={(search) => getOptionsFetcher(search)}
        labelKey="name"
        valueKey="id"
        control={control}
        id="linkedEntity"
        placeholder={t('page.articleManagement.placeholder.linkedEntity')}
        isTouched={touchedFields.linkedEntity}
        disabled={!selectedType}
      />

      <Button shape="circle" icon={MoreIcon} buttonType="submit" iconColor="white" isLoading={isMutationLoading} />
    </Form>
  )
}

export default ArticleManagementForm
