import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { SealedProductStoryblokStory } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type PostSealedProductStoryblokStoriesResponse = Api.PostSealedProductStoryblokStories.ResponseBody
export const postSealedProductStoryblokStory = async (body: PostSealedProductStoryblokStoriesResponse) => {
  const res = await playInApi.post<
    PostSealedProductStoryblokStoriesResponse,
    AxiosResponse<PostSealedProductStoryblokStoriesResponse>
  >(API_ROUTES.sealedProductStoryblokStories.root, body)

  return res.data
}

type FetchSealedProductStoryblokStoriesResponse = Api.GetSealedProductStoryblokStories.ResponseBody
export const fetchSealedProductStoryblokStories = async (): Promise<SealedProductStoryblokStory[]> => {
  const res = await playInApi.get<FetchSealedProductStoryblokStoriesResponse>(
    API_ROUTES.sealedProductStoryblokStories.root
  )

  return res.data['hydra:member']
}

export type DeleteSealedProductStoryblokStories = Api.DeleteSealedProductStoryblokStoriesId.RequestBody
export const deleteSealedProductStoryblokStories = async (id: number | undefined) => {
  const res = await playInApi.delete<
    DeleteSealedProductStoryblokStories,
    AxiosResponse<DeleteSealedProductStoryblokStories>
  >(`${API_ROUTES.sealedProductStoryblokStories.root}/${id}`)

  return res.data
}
