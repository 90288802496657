import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { RewardCategoryStoryblokStory } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type PostRewardCategoryStoryblokStoriesResponse = Api.PostRewardCategoryStoryblokStories.ResponseBody
export const postRewardCategoryStoryblokStory = async (body: PostRewardCategoryStoryblokStoriesResponse) => {
  const res = await playInApi.post<
    PostRewardCategoryStoryblokStoriesResponse,
    AxiosResponse<PostRewardCategoryStoryblokStoriesResponse>
  >(API_ROUTES.rewardCategoryStoryblokStories.root, body)

  return res.data
}

type FetchRewardCategoryStoryblokStoriesResponse = Api.GetRewardCategoryStoryblokStories.ResponseBody
export const fetchRewardCategoryStoryblokStories = async (): Promise<RewardCategoryStoryblokStory[]> => {
  const res = await playInApi.get<FetchRewardCategoryStoryblokStoriesResponse>(
    API_ROUTES.rewardCategoryStoryblokStories.root
  )

  return res.data['hydra:member']
}

export type DeleteRewardCategoryStoryblokStories = Api.DeleteRewardCategoryStoryblokStoriesId.RequestBody
export const deleteRewardCategoryStoryblokStories = async (id: number | undefined) => {
  const res = await playInApi.delete<
    DeleteRewardCategoryStoryblokStories,
    AxiosResponse<DeleteRewardCategoryStoryblokStories>
  >(`${API_ROUTES.rewardCategoryStoryblokStories.root}/${id}`)

  return res.data
}
