import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { FamilyStoryblokStory } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type PostFamilyStoryblokStoriesResponse = Api.PostFamilyStoryblokStories.ResponseBody
export const postFamilyStoryblokStory = async (body: PostFamilyStoryblokStoriesResponse) => {
  const res = await playInApi.post<
    PostFamilyStoryblokStoriesResponse,
    AxiosResponse<PostFamilyStoryblokStoriesResponse>
  >(API_ROUTES.familyStoryblokStories.root, body)

  return res.data
}

type FetchFamilyStoryblokStoriesResponse = Api.GetFamilyStoryblokStories.ResponseBody
export const fetchFamilyStoryblokStories = async (): Promise<FamilyStoryblokStory[]> => {
  const res = await playInApi.get<FetchFamilyStoryblokStoriesResponse>(API_ROUTES.familyStoryblokStories.root)

  return res.data['hydra:member']
}

export type DeleteFamilyStoryblokStories = Api.DeleteFamilyStoryblokStoriesId.RequestBody
export const deleteFamilyStoryblokStories = async (id: number | undefined) => {
  const res = await playInApi.delete<DeleteFamilyStoryblokStories, AxiosResponse<DeleteFamilyStoryblokStories>>(
    `${API_ROUTES.familyStoryblokStories.root}/${id}`
  )

  return res.data
}
