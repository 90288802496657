import classNames from 'classnames'
import BubbleIcon from 'components/BubbleIcon/BubbleIcon'
import {
  CalculateIcon,
  CartIcon,
  CollaboratorIcon,
  CommunicationIcon,
  InvoiceIcon,
  PlanningIcon,
  ProductIcon,
  StoreIcon,
  UserIcon
} from 'constants/icons'
import ShouldHide from 'features/Permissions/ShouldHide'
import AccoutNav from 'features/TopBar/AccountNav'
import useAuth from 'hooks/useAuth'
import { useModals } from 'hooks/useModals'
import { useAtom } from 'jotai'
import { RouteName } from 'permissions/permissions'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { Modals } from 'types/modals'
import { RoleAction } from 'types/playInApiInterfaces'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import CustomerSearchForm from '../forms/InputSearchForms/CustomerSearchForm'
import OrderSearchForm from '../forms/InputSearchForms/OrderSearchForm'
import SealedProductSearchForm from '../forms/InputSearchForms/SealedProductSearchForm'
import StockSearchForm from '../forms/InputSearchForms/StockSearchForm'
import {
  AccountNavContainer,
  Aside,
  Item,
  MainTitle,
  MenuItem,
  MenuSearch,
  StyledLink,
  StyledTextLink
} from './SideBar.styles'
import SideBarItemFold from './sideBarItemFold'

function SideBar({ collapsed }) {
  const { t } = useTranslation()
  const { me } = useAuth()
  const { openModal } = useModals()
  const [defaultStore] = useAtom(defaultStoreAtom)

  const { ref, inView } = useInView({
    threshold: 1 // Trigger component is in view when fully visible
  })

  return (
    <Aside className={collapsed ? 'close-sidebar' : 'open-sidebar'}>
      <AccountNavContainer>
        <AccoutNav />
      </AccountNavContainer>

      <MenuSearch ref={ref} className={classNames({ stuck: !inView })}>
        <ShouldHide permissions={[RoleAction.ROLE_ACTION_ORDERS_READ]}>
          <OrderSearchForm />
        </ShouldHide>
        <ShouldHide permissions={[RoleAction.ROLE_ACTION_CUSTOMERS_READ]}>
          <CustomerSearchForm />
        </ShouldHide>
        <ShouldHide permissions={[RoleAction.ROLE_ACTION_PRODUCTS_READ]}>
          <SealedProductSearchForm />
        </ShouldHide>
        <ShouldHide permissions={[RoleAction.ROLE_ACTION_STOCKS_PRINT]}>
          <StockSearchForm />
        </ShouldHide>
        {
          //Hide page without role for now
          /*
          <ShouldHide permissions={[RoleAction.ROLE_ACTION_CARDS_READ]}>
          <CardSearchForm />
        </ShouldHide>*/
        }
      </MenuSearch>

      <ShouldHide permissions={[RoleAction.ROLE_ACTION_STOCKS_READ]}>
        <MenuItem>
          <MainTitle>
            <BubbleIcon svg={InvoiceIcon} backgroundColor="purple" iconSize="2rem" />
            {t('navBar.stock.title')}
          </MainTitle>
          <Item>
            <SideBarItemFold label={t('navBar.stock.subtitles.buyAndTransfers')}>
              <ShouldHide permissions={[RoleAction.ROLE_ACTION_STOCK_CREATE]}>
                <StyledTextLink onClick={() => openModal(Modals.StockCreate)}>
                  {t('navBar.stock.link.create')}
                </StyledTextLink>
              </ShouldHide>
              <StyledLink route={RouteName.StockManagement} mode="hide">
                {t('navBar.stock.link.manage')}
              </StyledLink>
            </SideBarItemFold>
            <StyledLink route={RouteName.StockRange} mode="hide">
              {t('navBar.stock.link.range')}
            </StyledLink>
          </Item>
        </MenuItem>
      </ShouldHide>

      <ShouldHide permissions={[RoleAction.ROLE_ACTION_PRODUCTS_READ]}>
        <MenuItem>
          <MainTitle>
            <BubbleIcon svg={ProductIcon} backgroundColor="purple" />
            {t('navBar.product.title')}
          </MainTitle>
          <Item>
            <StyledLink route={RouteName.ProductManagement} mode="hide">
              {t('navBar.product.link.manage')}
            </StyledLink>
            <StyledLink route={RouteName.ProductRequestManagement} mode="hide">
              {t('navBar.product.link.productRequest')}
            </StyledLink>
            <StyledLink route={RouteName.CollectionCreate} mode="hide">
              {t('navBar.product.link.collectionCreate')}
            </StyledLink>
            <StyledLink route={RouteName.CollectionManagement} mode="hide">
              {t('navBar.product.link.collectionManage')}
            </StyledLink>
          </Item>
        </MenuItem>
      </ShouldHide>

      {
        //TODO Hide page without role for now
        /*<ShouldHide permissions={[RoleAction.ROLE_ACTION_CARDS_READ]}>
        <MenuItem>
          <MainTitle>
            <BubbleIcon svg={CardIcon} backgroundColor="purple" />
            {t('navBar.card.title')}
          </MainTitle>
          <Item>
            <StyledLink route={RouteName.CardManagement} mode="hide">
              {t('navBar.card.link.manage')}
            </StyledLink>
          </Item>
        </MenuItem>
  </ShouldHide>*/
      }

      <ShouldHide permissions={[RoleAction.ROLE_ACTION_ORDERS_READ]}>
        <MenuItem>
          <MainTitle>
            <BubbleIcon svg={CartIcon} backgroundColor="purple" />
            {t('navBar.order.title')}
          </MainTitle>
          <Item>
            <ShouldHide permissions={[RoleAction.ROLE_ACTION_ORDER_CREATE]}>
              <StyledTextLink onClick={() => openModal(Modals.OrderCreate)}>
                {t('navBar.order.link.create')}
              </StyledTextLink>
            </ShouldHide>
            <StyledLink route={RouteName.OrderManage} mode="hide">
              {t('navBar.order.link.manage')}
            </StyledLink>
            <StyledLink route={RouteName.OrderPrint} mode="hide">
              {t('navBar.order.link.print')}
            </StyledLink>
            <StyledLink route={RouteName.ShippingLabelLog} mode="hide">
              {t('navBar.order.link.shippingLabel')}
            </StyledLink>
            <StyledLink route={RouteName.OrderPreparationManage} mode="hide">
              {t('navBar.order.link.orderPreparationManage')}
            </StyledLink>
          </Item>
        </MenuItem>
      </ShouldHide>

      <ShouldHide permissions={[RoleAction.ROLE_ACTION_CUSTOMERS_READ]}>
        <MenuItem>
          <MainTitle>
            <BubbleIcon svg={UserIcon} backgroundColor="purple" />
            {t('navBar.customer.title')}
          </MainTitle>
          <Item>
            <StyledLink route={RouteName.CustomerManagement} mode="hide">
              {t('navBar.customer.link.manage')}
            </StyledLink>
          </Item>
        </MenuItem>
      </ShouldHide>

      <ShouldHide permissions={[RoleAction.ROLE_ACTION_LINKED_ARTICLE_EDIT]}>
        <MenuItem>
          <MainTitle>
            <BubbleIcon svg={CommunicationIcon} backgroundColor="purple" />
            {t('navBar.communication.title')}
          </MainTitle>
          <Item>
            <StyledLink route={RouteName.ArticleManagement} mode="hide">
              {t('navBar.communication.link.manage')}
            </StyledLink>
          </Item>
        </MenuItem>
      </ShouldHide>

      <ShouldHide
        permissions={[
          RoleAction.ROLE_ACTION_RESTOCKS_PRINT,
          RoleAction.ROLE_ACTION_STORE_LABEL_PRINT,
          RoleAction.ROLE_ACTION_GIFTCARD_ACTIVATE,
          RoleAction.ROLE_ACTION_STORES_READ
        ]}
      >
        <MenuItem>
          <MainTitle>
            <BubbleIcon svg={StoreIcon} backgroundColor="purple" />
            {t('navBar.store.title')}
          </MainTitle>
          <Item>
            <StyledLink route={RouteName.RestockStorePrint} mode="hide">
              {t('navBar.store.link.restock')}
            </StyledLink>
            <StyledLink route={RouteName.StoreLabels} mode="hide">
              {t('navBar.store.link.labels')}
            </StyledLink>
            <StyledLink route={RouteName.GiftCardManagement} mode="hide">
              {t('navBar.store.link.giftcard')}
            </StyledLink>
            <ShouldHide permissions={[RoleAction.ROLE_ACTION_STORE_CREATE, RoleAction.ROLE_ACTION_STORES_READ]}>
              <SideBarItemFold label={t('navBar.store.subtitles.playinStore')}>
                <ShouldHide permissions={[RoleAction.ROLE_ACTION_STORE_CREATE]}>
                  <StyledTextLink onClick={() => openModal(Modals.StoreCreate)}>
                    {t('navBar.store.link.create')}
                  </StyledTextLink>
                </ShouldHide>

                <ShouldHide
                  permissions={[RoleAction.ROLE_ACTION_STORES_READ]}
                  deniedExtraCondition={me?.stores && me.stores.length < 2}
                >
                  <StyledLink route={RouteName.StoreManagement} mode="hide">
                    {t('navBar.store.link.manage')}
                  </StyledLink>
                </ShouldHide>

                <StyledLink route={RouteName.StoreDetails} value={defaultStore?.id} mode="hide">
                  {defaultStore?.name}
                </StyledLink>
              </SideBarItemFold>
            </ShouldHide>
          </Item>
        </MenuItem>
      </ShouldHide>

      <ShouldHide permissions={[RoleAction.ROLE_ACTION_CASHBOXES_WRITE]}>
        <MenuItem>
          <MainTitle>
            <BubbleIcon svg={CalculateIcon} backgroundColor="purple" />
            {t('navBar.cashbox.title')}
          </MainTitle>
          <Item>
            <StyledLink route={RouteName.CashboxManagement} mode="hide">
              {t('navBar.cashbox.link.manage')}
            </StyledLink>

            <StyledLink
              route={
                //"My cashbox" actually refer to CashboxCreate route (/cashbox-details)
                //Using CashboxDetails route is causing troubleshooting when we navigate from cashbox-details/id to "My cashbox"
                RouteName.CashboxCreate
              }
              mode="hide"
            >
              {t('navBar.cashbox.link.detail')}
            </StyledLink>
          </Item>
        </MenuItem>
      </ShouldHide>

      <ShouldHide permissions={[RoleAction.ROLE_ACTION_STORE_EVENTS_READ]}>
        <MenuItem>
          <MainTitle>
            <BubbleIcon svg={PlanningIcon} backgroundColor="purple" />
            {t('navBar.event.title')}
          </MainTitle>
          <Item>
            <StyledLink route={RouteName.StoreEventCreate} mode="hide">
              {t('navBar.event.link.create')}
            </StyledLink>
            <StyledLink route={RouteName.StoreEventManage} mode="hide">
              {t('navBar.event.link.manage')}
            </StyledLink>
          </Item>
        </MenuItem>
      </ShouldHide>

      <MenuItem>
        <MainTitle>
          <BubbleIcon svg={CollaboratorIcon} backgroundColor="purple" />
          {t('navBar.collaborator.title')}
        </MainTitle>
        <Item>
          <ShouldHide permissions={[RoleAction.ROLE_ACTION_COLLABORATORS_READ]}>
            <StyledLink route={RouteName.CollaboratorManagement} mode="hide">
              {t('navBar.collaborator.link.manage')}
            </StyledLink>
          </ShouldHide>

          <ShouldHide permissions={[RoleAction.ROLE_ACTION_PERMISSIONS_MANAGE_READ]}>
            <StyledLink route={RouteName.PermissionManage} mode="hide">
              {t('navBar.collaborator.link.permissionManage')}
            </StyledLink>
          </ShouldHide>

          <StyledLink route={RouteName.CollaboratorAccountOptions} mode="hide">
            {t('navBar.collaborator.link.collaboratorAccountOptions')}
          </StyledLink>
        </Item>
      </MenuItem>
    </Aside>
  )
}

export default SideBar
