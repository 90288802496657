import { getOrderQuickSearchUri, useOrdersPaginatedQuery } from 'api/orders'
import { SearchForm, useInputSearchForm } from 'components/InputSearchForm/InputSearchForm'
import { CartIcon } from 'constants/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SortingDirection } from 'types/sorting'

const defaultQueryParams = { 'order[createdAt]': SortingDirection.Desc }

const OrderSearchForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const { reset, InputSearchForm } = useInputSearchForm()

  const { isFetching } = useOrdersPaginatedQuery(
    ['orderSidebar', search, defaultQueryParams],
    getOrderQuickSearchUri(search, defaultQueryParams),
    {
      enabled: search !== '',
      onSuccess: (data) => {
        if (data.length === 1) {
          navigate(`/order-details/${data[0].id}`)
        } else {
          navigate('/order-manage', { state: { search } })
        }
        setSearch('')
        reset()
      }
    }
  )

  const onSearch = async (formData: SearchForm) => {
    if (!formData.value.trim()) {
      navigate('/order-manage')
    } else {
      setSearch(formData.value)
    }
  }

  return (
    <InputSearchForm
      svg={CartIcon}
      text={t('inputSearchForm.tooltip.order.test')}
      isLoading={isFetching}
      onSearch={onSearch}
      title={t('inputSearchForm.tooltip.orderTitle')}
      inputProps={{ id: 'sidebarSearchOrder' }}
      placeholder={t('navBar.order.title')}
    />
  )
}

export default OrderSearchForm
