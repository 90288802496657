import { GetOrderByIdResponse } from 'api/orders'
import { FamilyIcon } from 'components/Icon/Icon.styles'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { useCardFullName } from 'hooks/entityHooks/cardHooks'
import { useTranslation } from 'react-i18next'
import { CreditNoteEntry } from 'types/entities'
import { CardProductProductReadProductStats, SealedProductProductRead } from 'types/playInApiInterfaces'
import { RowContainer, RowCreditNote, StyledListing } from './OrderEntriesTable.styles'

type Props = {
  order?: GetOrderByIdResponse
}

const OrderCreditNoteEntriesTable = ({ order }: Props) => {
  const { t } = useTranslation()
  const { getCardFullName } = useCardFullName()

  const columns: Column<CreditNoteEntry>[] = [
    {
      key: 'icon',
      decorator: (creditNoteEntry) => (
        <RowContainer>
          <FamilyIcon src={creditNoteEntry?.product?.family?.iconUrl!} title={creditNoteEntry?.product?.family?.name} />
        </RowContainer>
      )
    },
    {
      key: 'quantity',
      name: t('common.label.quantity'),
      decorator: (creditNoteEntry: CreditNoteEntry) => {
        return (
          <RowContainer>
            <Text>{creditNoteEntry.quantity}</Text>
          </RowContainer>
        )
      }
    },
    {
      key: 'cardOrProduct',
      name: t('common.label.cardOrProduct'),
      decorator: (creditNoteEntry: CreditNoteEntry) => {
        if (creditNoteEntry.product?.['@type'] === 'CardProduct') {
          const cardProduct = creditNoteEntry.product as CardProductProductReadProductStats
          return (
            <RowContainer>
              <Text>{getCardFullName(cardProduct)}</Text>
            </RowContainer>
          )
        } else {
          const sealedProduct = creditNoteEntry.product as SealedProductProductRead
          return (
            <RowContainer>
              <Text>{sealedProduct?.name}</Text>
            </RowContainer>
          )
        }
      }
    },
    {
      key: 'lang',
      name: t('common.label.lang'),
      decorator: (creditNoteEntry: CreditNoteEntry) => {
        const langCode =
          creditNoteEntry.product?.['@type'] === 'CardProduct'
            ? (creditNoteEntry.product as CardProductProductReadProductStats)?.cardData?.declination?.lang?.code
            : (creditNoteEntry.product as SealedProductProductRead)?.lang?.code
        return (
          <RowContainer>
            <Text>{langCode?.toLocaleUpperCase()}</Text>
          </RowContainer>
        )
      }
    },
    {
      key: 'totalPrice',
      name: t('common.label.totalUnitPrice'),
      decorator: (creditNoteEntry: CreditNoteEntry) => (
        <RowContainer>
          <TextNumber value={creditNoteEntry.price! * creditNoteEntry.quantity!} suffix=" €" decimalScale={2} />
        </RowContainer>
      )
    }
  ]

  return (
    <>
      {order?.creditNotes?.map((creditNote) => (
        <CardLayout>
          <>
            <Title2>{t('page.order.detail.creditNoteEntries.title', { id: creditNote.id })}</Title2>

            {(!!creditNote.shippingFees ||
              !!creditNote.paymentFees ||
              !!creditNote.commercialGesture ||
              !!creditNote.rentalGesture ||
              !!creditNote.couponDiscount) && (
              <RowCreditNote>
                {!!creditNote.shippingFees && (
                  <Text>
                    {t('page.order.detail.creditNoteEntries.shippingFees', {
                      value: creditNote.shippingFees.toFixed(2)
                    })}
                  </Text>
                )}
                {!!creditNote.paymentFees && (
                  <Text>
                    {t('page.order.detail.creditNoteEntries.paymentFees', { value: creditNote.paymentFees.toFixed(2) })}
                  </Text>
                )}
                {!!creditNote.commercialGesture && (
                  <Text>
                    {t('page.order.detail.creditNoteEntries.commercialGesture', {
                      value: creditNote.commercialGesture.toFixed(2)
                    })}
                  </Text>
                )}
                {!!creditNote.rentalGesture && (
                  <Text>
                    {t('page.order.detail.creditNoteEntries.rentalGesture', {
                      value: creditNote.rentalGesture.toFixed(2)
                    })}
                  </Text>
                )}
                {!!creditNote.couponDiscount && (
                  <Text>
                    {t('page.order.detail.creditNoteEntries.couponDiscount', {
                      value: creditNote.couponDiscount.toFixed(2)
                    })}
                  </Text>
                )}
              </RowCreditNote>
            )}

            {typeof creditNote.entries !== 'undefined' && (
              <StyledListing
                data={creditNote.entries}
                columns={columns}
                emptyText=""
                totalCount={creditNote.entries.length}
              />
            )}
          </>
        </CardLayout>
      ))}
    </>
  )
}

export default OrderCreditNoteEntriesTable
