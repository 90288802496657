import { deleteCardEditionStoryblokStories, fetchCardEditionStoryblokStories } from 'api/cardEditionStoryblokStories'
import { deleteCategoryStoryblokStories, fetchCategoryStoryblokStories } from 'api/categoryStoryblokStories'
import { deleteFamilyCardStoryblokStories, fetchFamilyCardStoryblokStories } from 'api/familyCardStoryblokStories'
import { deleteFamilyStoryblokStories, fetchFamilyStoryblokStories } from 'api/familyStoryblokStories'
import {
  deleteProductCollectionStoryblokStories,
  fetchProductCollectionStoryblokStories
} from 'api/productCollectionStoryblokStories'
import {
  deleteRewardCategoryStoryblokStories,
  fetchRewardCategoryStoryblokStories
} from 'api/rewardCategoryStoryblokStories'
import {
  deleteSealedProductStoryblokStories,
  fetchSealedProductStoryblokStories
} from 'api/sealedProductStoryblokStories'
import { Section } from 'components/Section/Section.styles'
import MainTitle from 'components/Title/MainTitle'
import { useTranslation } from 'react-i18next'
import ArticleManagementForm from './form/ArticleManagementForm'
import ArticleManagementTable from './table/ArticleManagementTable'

export type UpdateCredentialsInputs = {
  oldPassword?: string
  newPassword?: string
  checkPassword?: string
}

export default function ArticleManagement() {
  const { t } = useTranslation()

  return (
    <Section>
      <MainTitle title={t('page.articleManagement.title')} />
      <ArticleManagementForm></ArticleManagementForm>
      <ArticleManagementTable
        title={t('page.articleManagement.table.familyCards')}
        queryFn={fetchFamilyCardStoryblokStories}
        deleteFn={deleteFamilyCardStoryblokStories}
        type={'family-cards'}
        mapper={{
          id: (item) => item.id!,
          label: (item) => item?.family?.transName ?? '',
          storyId: (item) => item.storyId!
        }}
      />
      <ArticleManagementTable
        title={t('page.articleManagement.table.categories')}
        queryFn={fetchCategoryStoryblokStories}
        deleteFn={deleteCategoryStoryblokStories}
        type={'product-categories'}
        mapper={{
          id: (item) => item.id!,
          label: (item) => item?.category?.transName ?? '',
          storyId: (item) => item.storyId!
        }}
      />
      <ArticleManagementTable
        title={t('page.articleManagement.table.cardEditions')}
        queryFn={fetchCardEditionStoryblokStories}
        deleteFn={deleteCardEditionStoryblokStories}
        type={'card-editions'}
        mapper={{
          id: (item) => item.id!,
          label: (item) => item?.cardEdition?.transName ?? '',
          storyId: (item) => item.storyId!
        }}
      />
      <ArticleManagementTable
        title={t('page.articleManagement.table.families')}
        queryFn={fetchFamilyStoryblokStories}
        deleteFn={deleteFamilyStoryblokStories}
        type={'families'}
        mapper={{
          id: (item) => item.id!,
          label: (item) => item?.family?.transName ?? '',
          storyId: (item) => item.storyId!
        }}
      />
      <ArticleManagementTable
        title={t('page.articleManagement.table.sealedProducts')}
        queryFn={fetchSealedProductStoryblokStories}
        deleteFn={deleteSealedProductStoryblokStories}
        type={'sealed-products'}
        mapper={{
          id: (item) => item.id!,
          label: (item) => item?.sealedProduct?.transName ?? '',
          storyId: (item) => item.storyId!
        }}
      />
      <ArticleManagementTable
        title={t('page.articleManagement.table.rewardCategories')}
        queryFn={fetchRewardCategoryStoryblokStories}
        deleteFn={deleteRewardCategoryStoryblokStories}
        type={'reward-categories'}
        mapper={{
          id: (item) => item.id!,
          label: (item) => item?.rewardCategory?.transName ?? '',
          storyId: (item) => item.storyId!
        }}
      />
      <ArticleManagementTable
        title={t('page.articleManagement.table.productCollections')}
        queryFn={fetchProductCollectionStoryblokStories}
        deleteFn={deleteProductCollectionStoryblokStories}
        type={'product-collections'}
        mapper={{
          id: (item) => item.id!,
          label: (item) => item?.productCollection?.transName ?? '',
          storyId: (item) => item.storyId!
        }}
      />
    </Section>
  )
}
